import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory
} from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import theme from '../theme.js';
import LoadingIndicator from '../Components/LoadingIndicator';
import TopToolbar from '../Components/nci/TopToolbar';
import ColumnHolder from '../Components/nci/ColumnHolder';
import NCIDrawer from '../Components/nci/Drawer';
import { ReactComponent as Arrow } from '../Components/Psyop/arrow.svg';
import ReactSvgMap from '../Components/nci/ReactSvgMap';
import CountryDetails from './NCICountryDetails';
import { fetchZone, fetchZones } from '../Api';

import './styles/nci-dashboard.scss';

export const ColorLine = [
  { value: 66, color: '#68CC58' },
  { value: 33, color: '#FFC32A' },
  { value: -1, color: '#D14154' }
];

export const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  mission_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  background: {
    background: 'linear-gradient(180deg, #383945 0%, #212229 100%)',
    backgroundSize: 'cover'
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      overflowY: 'hidden'
    }
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& > div': {
      color: 'white'
    }
    // opacity:1,
    // transition: "opacity .5s",
    // transitionDelay: '1s'
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity .25s',
    transitionDelay: '0s'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity .05s',
    transitionDelay: '.15s'
  },

  // National index - column 1
  reportBox: {
    margin: '0 8px',
    padding: '0px 8px 16px',
    borderBottom: '1px solid #454654'
  },
  reportNumber: {
    fontSize: 64,
    fontWeight: 700,
    color: '#888DE0',
    lineHeight: 1
  },
  indexArrow: {
    width: '38px',
    marginLeft: theme.spacing(2)
  },
  arrowUpActive: {
    '& path': {
      fill: '#8EDE40'
    }
  },
  arrowDown: {
    transform: 'rotate(180deg)'
  },
  arrowDownActive: {
    '& path': {
      fill: '#EE1C25'
    }
  },
  reportName: {
    fontSize: '16px',
    fontWeight: 400
  },
  reportInfo: {
    color: '#898AA1',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'right'
  },
  chart: {
    '& path': {
      stroke: '#cdcdcd',
      strokeWidth: 0.5
    },
    '& path[stroke-opacity]': {
      stroke: '#888DE0',
      strokeOpacity: 1,
      strokeWidth: '6px'
    }
  }
}));

const mapZonesToRegions = zones =>
  (zones?.data || []).map(zone => ({
    id: zone.id,
    name: zone.attributes.name,
    flag: zone.attributes.direct_flag_url,
    globe: zone.attributes.direct_globe_picture_url
  }));

const NCIDashboard = props => {
  const classes = useStyles(theme);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [actorDetails, setActorDetails] = useState(false);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stats, setStats] = useState(undefined);
  const [tag, setTag] = useState('All');

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const fetchRegions = async () => {
    try {
      const zones = await fetchZones();
      const regions = mapZonesToRegions(zones);
      setRegions(regions);
      return regions;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCountries = async (zoneId, shouldLoading = false) => {
    if (shouldLoading) setIsLoading(true);
    setTag('All');
    const _zone = await fetchZone(zoneId);
    const responseCountries = _.map(
      _zone.data.attributes.country_details,
      _c => ({
        ..._c,
        score: _.round(_.toNumber(_c.score), 1) || 0
      })
    );
    const orderCountries = _.orderBy(
      _.uniqBy(responseCountries, 'actor_id'),
      'actor_name'
    );

    const categoriesArray = _.map(orderCountries, country => {
      const countryCategories = _.map(country.categories, category => ({
        ...category,
        actor_id: country.actor_id,
        actor_name: country.actor_name,
        score: _.round(category.score, 1) || 0
      }));
      return countryCategories;
    });

    const categories = categoriesArray.reduce((a, b) => a.concat(b), []);
    const stats = _.groupBy(categories, 'category_name');
    const allStats = _.map(orderCountries, country => {
      return {
        actor_id: country.actor_id,
        actor_name: country.actor_name,
        category_id: 999999999,
        category_name: 'All',
        score: _.round(country.total_score, 1) || 0
      };
    });
    setCountries(orderCountries);
    setStats({ ...stats, All: allStats });
    setSelectedCountry(orderCountries[0]);
    if (shouldLoading) setIsLoading(false);
  };

  const initialLoad = async () => {
    setIsLoading(true);
    const regions = await fetchRegions();

    // set first region as default
    setSelectedRegion(regions[0]);
    await fetchCountries(regions[0].id);

    setIsLoading(false);
  };

  useEffect(async () => {
    document.title = 'NCI Dashboard';

    initialLoad();
  }, []);

  const onChangeRegionSelection = region => {
    const isDetailedScreen = matchPath(
      history?.location?.pathname,
      '/nci/details'
    );
    if (isDetailedScreen) history.push('/nci');

    setSelectedRegion(region);
    fetchCountries(region.id, true);
  };

  const onChangeCountrySelection = actor_id => {
    const country = _.find(countries, { actor_id });
    setTag('All');
    setSelectedCountry(country);
  };

  const selectedStat = _.find(_.get(stats, tag), {
    actor_id: selectedCountry?.actor_id
  });

  const CountryList = selectedRegion && (
    <>
      <Container maxWidth="xl" className={classes.mission_container}>
        <TopToolbar title={`COCOM > ${_.upperCase(selectedRegion.name)}`} />
      </Container>
      {stats && (
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3} className={clsx(classes.parentGrid)}>
            <ColumnHolder
              subtitle="NATIONAL CONFIDENCE INDEX"
              gridProps={{
                lg: 4,
                sm: 12
              }}
              noHorizontalPadding
            >
              <Box marginTop="15px" display="flex" flex={1} overflow="hidden">
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" className={classes.reportBox}>
                    <Typography variant="h2" className={classes.reportNumber}>
                      {_.get(selectedStat, 'score', '-')}
                    </Typography>
                    {selectedStat && (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Arrow
                            className={clsx(
                              classes.indexArrow,
                              classes.arrowUp,
                              true ? classes.arrowUpActive : ''
                            )}
                          />
                          <Arrow
                            className={clsx(
                              classes.indexArrow,
                              classes.arrowDown,
                              false ? classes.arrowDownActive : ''
                            )}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          flex={1}
                          alignItems="flex-end"
                        >
                          <Typography className={classes.reportName}>
                            {selectedStat.actor_name}’s {tag} Score
                          </Typography>
                          <Typography className={classes.reportInfo}>
                            +0% Overall Sentiment
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>

                  <div className="nci-report-tags-wrapper">
                    <span className="nci-report-tags-label">Tags</span>
                    <div className="nci-report-tags">
                      {_.map(_.sortBy(_.keys(stats)), key => {
                        return (
                          <span
                            key={key}
                            className={`nci-report-tags-item ${
                              key === tag
                                ? 'nci-report-tags-item--selected'
                                : ''
                            }`}
                            onClick={() => setTag(key)}
                          >
                            {key}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <Box flex={1} overflow="hidden" marginBottom="10px">
                    <div className="nci-report__list">
                      {_.map(
                        _.orderBy(_.get(stats, tag), 'actor_name'),
                        country => {
                          const colorLine = ColorLine.find(
                            ({ value }) => value < country.score
                          );
                          return (
                            <div
                              key={country.actor_name.split(' ').join('')}
                              className={`nci-report__list-item ${
                                selectedCountry?.actor_id == country.actor_id
                                  ? 'nci-report__list-item--selected'
                                  : ''
                              }`}
                              onClick={() => setSelectedCountry(country)}
                            >
                              <span className="nci-report__list-name">
                                {country.actor_name}
                              </span>
                              <div className="nci-report__list-bar-wrapper">
                                <span className="nci-report__list-bar">
                                  <span
                                    className="nci-report__list-bar-result"
                                    style={{
                                      width: `${country.score}%`,
                                      backgroundColor: colorLine.color
                                    }}
                                  />
                                </span>
                                <span className="nci-report__list-result">
                                  {country.score}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </ColumnHolder>
            <ColumnHolder
              desc="Active Campaigns"
              gridProps={{
                lg: 8,
                sm: 12
              }}
            >
              <ReactSvgMap
                countries={_.map(countries, ({ actor_name, actor_id }) => ({
                  name: actor_name,
                  value: _.get(
                    _.find(_.get(stats, tag), { actor_id }),
                    'score',
                    0
                  )
                }))}
                selectedCountry={selectedCountry?.actor_name}
                onSelectCountry={_c => {
                  setSelectedCountry(_.find(countries, { actor_name: _c }));
                }}
                cropSingleCountry={false}
              />
            </ColumnHolder>
          </Grid>
        </Container>
      )}
    </>
  );

  return (
    <React.Fragment>
      <Box
        className={clsx(
          isLoading ? classes.fadeIn : classes.fadeOut,
          classes.loading
        )}
      >
        <LoadingIndicator />
      </Box>
      <NCIDrawer
        countries={countries}
        regions={regions}
        onChangeRegion={onChangeRegionSelection}
        onChangeCountry={onChangeCountrySelection}
        selectedRegion={selectedRegion}
        selectedCountry={selectedCountry}
        onClickDetails={() => setActorDetails()}
      />
      <main className={clsx(classes.content, classes.background)}>
        <Switch>
          <Route
            exact
            path="/nci"
            render={() => {
              return CountryList;
            }}
          />
          <Route
            exact
            path="/nci/details"
            render={() => (
              <CountryDetails
                isLoading={isLoading}
                region={selectedRegion}
                country={selectedCountry}
                {...props}
              />
            )}
          />
          <Redirect to="/nci" />
        </Switch>
      </main>
    </React.Fragment>
  );
};

export default NCIDashboard;
