import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  Fragment
} from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import theme from '../../theme.js';
import Scout from './Scout.js';
import Analyze from './Analyze.js';
import Message from './Message';
import Amplify from './Amplify';

import {
  useAuthDispatch,
  logout,
  fetchMissionData,
  useAuthState
} from '../../Context';
import useWindowDimensions, {
  useIsLowScreen
} from '../../Hooks/useWindowDimensions';

const NavPortal = ({ children }) => {
  const el = document.getElementById('nav-content');
  return el && ReactDOM.createPortal(children, el);
};

const useStyles = makeStyles(theme => ({
  tabHolder: {
    paddingLeft: theme.spacing(12)
  },
  tabHolderWithLowScreen: {
    paddingLeft: 0
  },
  tabs: {
    minHeight: '0px'
  },
  activeTab: {
    borderLeft: '1px solid #575865',
    borderRight: '1px solid #575865',
    borderBottom: '1px solid transparent',
    color: 'white',
    '&>*': {
      marginLeft: '0px',
      marginRight: '0px'
    }
  },
  inactiveTab: {
    borderBottom: '1px solid #575865',
    '&>*': {
      marginLeft: '1px',
      marginRight: '1px'
    }
  },
  tab: {
    fontSize: 16,
    background: '#383945',
    marginBottom: '0px',
    zIndex: 99,
    textTransform: 'none',
    fontWeight: 500,
    minHeight: 0,
    padding: theme.spacing(1, 5)
  },

  indicator: {
    height: '0px'
  },
  tabFix: {
    borderTop: '1px solid #575865',
    marginTop: '-1px'
  },
  parentGrid: {
    marginTop: theme.spacing(2)
  },
  fadeOut: {
    opacity: 0,
    willChange: 'opacity'
    // transition:'opacity .25s'
  },
  fadeIn: {
    opacity: 1,
    willChange: 'opacity'
    // transition:'opacity .25s'
  }
}));

export default function PsyopCampaign({
  active,
  currentTarget,
  handleTargetSelect,
  handleSetDrawerDropdown
}) {
  let classes = useStyles(theme);
  const { projectData, missionData, missionLoading, missionErrorMessage } =
    useAuthState();
  const [value, setValue] = React.useState(0);
  const isLowScreen = useIsLowScreen();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value == 1) {
      handleSetDrawerDropdown(
        missionData?.audience_demographics?.attributes?.targeting
      );
    } else {
      handleSetDrawerDropdown(null);
    }
  }, [value]);

  const renderSwitch = param => {
    switch (param) {
      case 0: //Scout
        return (
          <Scout
            currentTarget={currentTarget}
            handleTargetSelect={handleTargetSelect}
          />
        );
      case 1: //Analyze
        return <Analyze />;
      case 2: //Message
        return <Message />;
      case 3: //Amplify
        return <Amplify />;
      default:
        return <React.Fragment />;
    }
  };

  const renderTabs = () => {
    const Wrapper = isLowScreen ? NavPortal : Fragment;
    return (
      <Wrapper>
        <Box
          className={clsx(
            classes.tabHolder,
            isLowScreen ? classes.tabHolderWithLowScreen : ''
          )}
        >
          <Tabs
            classes={{
              indicator: classes.indicator,
              root: classes.tabs
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab
              className={clsx(
                value == 0 ? classes.activeTab : classes.inactiveTab,
                classes.tab
              )}
              label={`${!isLowScreen ? '01.' : ''} Polling`}
            />
            <Tab
              className={clsx(
                value == 1 ? classes.activeTab : classes.inactiveTab,
                classes.tab
              )}
              label={`${!isLowScreen ? '02.' : ''} Demographics`}
            />
            <Tab
              disabled
              className={clsx(
                value == 2 ? classes.activeTab : classes.inactiveTab,
                classes.tab
              )}
              label={`${!isLowScreen ? '03.' : ''} Narrative Testing`}
            />
            <Tab
              disabled
              className={clsx(
                value == 3 ? classes.activeTab : classes.inactiveTab,
                classes.tab
              )}
              label={`${!isLowScreen ? '04.' : ''} Broadcasting`}
            />
          </Tabs>
        </Box>
      </Wrapper>
    );
  };

  return (
    <React.Fragment>
      {renderTabs()}
      {!isLowScreen && <Divider className={classes.tabFix} />}
      <Grid
        container
        spacing={3}
        className={clsx(
          active ? classes.fadeIn : classes.fadeOut,
          classes.parentGrid
        )}
      >
        {renderSwitch(value)}
      </Grid>
    </React.Fragment>
  );
}
