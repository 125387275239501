import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react';
import clsx from 'clsx';

import theme from '../../theme.js';

import MissionStats from './MissionStats';
// import MissionDetail from './MissionDetail';
import {
  useAuthDispatch,
  logout,
  fetchMissionData,
  useAuthState
} from '../../Context';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const TargetMission = forwardRef(
  ({ handleTargetSelect, currentTarget, handleLogout, detailOpen }, ref) => {
    // const { loading, data } = useFetch("/data.json");
    const classes = useStyles(theme);
    const { missionData, missionLoading, missionErrorMessage } = useAuthState(); //read user details from context

    return (
      <React.Fragment>
        {missionData && (
          <MissionStats
            active={!detailOpen}
            handleTargetSelect={handleTargetSelect}
            selectedTarget={currentTarget}
          />
        )}
      </React.Fragment>
    );
  }
);

export default TargetMission;
