/* eslint-disable no-use-before-define */

import React, { useEffect } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from '../theme.js';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';

import SettingsButton from './SettingsButton';
import {
  setProject as setProjectContext,
  useAuthState,
  useAuthDispatch
} from '../Context';
import useWindowDimensions, {
  useIsLowScreen
} from '../Hooks/useWindowDimensions';
import { spacing } from '@material-ui/system';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    flexGrow: 0,
    minWidth: 120,
    fontSize: 21,
    '&::before': {
      border: 'none'
    }
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
    // transform:'none'
  },
  mission_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  missionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar,

    minHeight: 'auto !important'
  },
  grid: {
    // paddingLeft:theme.spacing(8),
    // paddingRight:theme.spacing(8),
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: theme.spacing(0.25)
    },
    boxShadow: '0px 12px 18px rgba(0, 0, 0, 0.24)'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#575865', 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    '& .MuiInputLabel-shrink': {
      display: 'none'
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  },
  searchIcon: {
    right: 0,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    // color: 'inherit',
    '&:hover': {
      backgroundColor: 'none'
    },
    color: theme.palette.common.white
  },
  inputInput: {
    color: theme.palette.common.white,
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('width'),
    width: '100%',
    '& .MuiInputLabel-shrink': {
      opacity: 0
      // transform: 'translate(0, 1.5px)!important',
    },
    '& .MuiInputLabel-formControl': {
      // transform : 'translate(0, 30px)'
    }

    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  rowActive: {
    opacity: 1.0,
    outline: 'solid 1px',
    color: 'white',
    transition: 'opacity .25s'
  },
  missionTitle: {
    opacity: 1.0,
    color: '#595A6B',
    transition: 'opacity .25s',
    fontSize: '12px'
    // width:'95px'
  },
  percentage: {
    color: '#888DE0',
    fontStyle: 'bold',
    fontWeight: 700
  },
  blueText: {
    color: '#888DE0'
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  buttonWithLowScreen: {
    padding: '0 0 0 12px',

    '& .MuiAvatar-root': {
      height: '67px !important',
      width: '67px !important'
    }
  },
  boxDrop: {
    flex: 1,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      // width: '80%',
      padding: theme.spacing(0, 0.5)
    }
  },
  formControl: {
    '& label': {
      textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px)!important'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 30px)'
    }
  },
  formControlWithLowScreen: {
    padding: theme.spacing(0, 2),

    '& .MuiInputBase-root': {
      fontSize: '18px'
    }
  },
  hideLowScreen: {
    display: 'none'
  },
  gridLowHeight: {
    '& label + .MuiInput-formControl': {
      marginTop: 0
    }
  },
  headerContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  headerContainerWithLowScreen: {
    position: 'relative',

    '&:after': {
      [theme.breakpoints.up('md')]: {
        content: '" "',
        bottom: '14px',
        left: '75px',
        position: 'absolute',
        right: 0,
        height: '1px',
        backgroundColor: '#575865',
        zIndex: 1
      }
    }
  },
  mainHeader: {
    flexBasis: '66%',
    maxWidth: '66%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: 'none'
    },

    '& #nav-content': {
      flex: 1,
      marginBottom: '-1px'
    }
  },
  mainHeaderWithLowScreen: {
    flex: 1,
    maxWidth: 'none'
  },
  links: {
    flexGrow: 1
  },
  linksWithLowScreen: {
    flexGrow: 0,

    '& .MuiButtonBase-root': {
      padding: '14px',

      '& svg': {
        height: '18px'
      }
    }
  },
  boxDropWithLowScreen: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacing(2),
    paddingLeft: 0,

    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      display: 'block'
    },

    '& .MuiTab-root': {
      padding: theme.spacing(0.5, 3)
    }
  }
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: '#575865',

      '& fieldset': {
        borderColor: 'white',
        border: 'none'
      },
      '&:hover fieldset': {
        borderColor: 'white',
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        border: 'none'
      },
      '& .MuiInputLabel-shrink': {
        opacity: 0
        // transform: 'translate(0, 1.5px)!important',
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '8px',

      '& input': {
        padding: 0
      },
      '& svg': {
        width: '21px'
      }
    },
    '& label.MuiInputLabel-outlined': {
      color: 'white',
      transform: 'translate(14px, 11px) scale(1)',
      fontSize: '14px'
    }
  }
})(TextField);

export default function TopToolbar({
  handleTargetSelect,
  handleDetailOpen,
  handleLogout,
  selectedTarget
}) {
  const classes = useStyles(theme);
  const {
    missionData,
    projectData,
    currentProject,
    allProjects,
    missionLoading
  } = useAuthState(); //read user details from context
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const isLowScreen = useIsLowScreen();
  const [project, setProject] = React.useState('');
  const [searchInputValue, setSearchInputValue] = React.useState('');
  // const [projects, setProjects] = React.useState( allProjects);

  const handleChange = event => {
    setProject(event.target.value);
  };

  useEffect(() => {
    if (allProjects.length) {
      setProject(allProjects[0].name);
    }
  }, [allProjects]);

  useEffect(() => {
    setProjectContext(
      dispatch,
      allProjects.find((v, i) => v.name == project)
    );
  }, [project]);

  return (
    <Box
      container
      className={clsx(
        classes.headerContainer,
        isLowScreen ? classes.headerContainerWithLowScreen : '',
        isLowScreen ? classes.gridLowHeight : ''
      )}
    >
      <Box
        className={clsx(
          classes.mainHeader,
          isLowScreen ? classes.mainHeaderWithLowScreen : ''
        )}
      >
        <div className={classes.missionBar}>
          <Tooltip title="Mission Details">
            <IconButton
              className={clsx(
                classes.button,
                isLowScreen ? classes.buttonWithLowScreen : ''
              )}
              onClick={handleDetailOpen}
              disabled
            >
              <Avatar
                alt="Mission Avatar"
                src="/mission_avatar.png"
                className={classes.avatar}
              />
            </IconButton>
          </Tooltip>
          <Box
            className={clsx(
              classes.boxDrop,
              isLowScreen ? classes.boxDropWithLowScreen : ''
            )}
          >
            <FormControl
              className={clsx(
                classes.formControl,
                classes.formControlWithLowScreen
              )}
            >
              <InputLabel
                className={classes.subtitle}
                id="mission-select-label"
              ></InputLabel>
              <Select
                disabled={missionLoading}
                labelId="mission-select-label"
                id="mission-select"
                value={project}
                onChange={handleChange}
                className={classes.title}
                disableUnderline
              >
                {allProjects &&
                  allProjects.map((v, i) => (
                    <MenuItem key={`${v.name}menuNav`} value={v.name}>
                      {v.name}
                    </MenuItem>
                  ))}
                {/*projectData &&
                  <MenuItem value={projectData.name}>{projectData.name}</MenuItem>
              */}
              </Select>
            </FormControl>
            {isLowScreen && <div id="nav-content" />}
            {projectData && !isLowScreen && (
              <>
                <Box display="flex" pl={1} pr={2}>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 773 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9" cy="12" r="12" fill="#2F3039" />
                    <circle cx="197" cy="12" r="12" fill="#2F3039" />
                    <circle cx="385" cy="12" r="12" fill="#2F3039" />
                    <circle cx="573" cy="12" r="12" fill="#2F3039" />
                    <circle cx="761" cy="12" r="12" fill="#2F3039" />
                    <path
                      d="M6 12.0001L760 12.0001"
                      stroke="#2F3039"
                      strokeWidth="12"
                      strokeLinecap="round"
                    />
                    <path
                      d="M6 12.0001L760 12.0001"
                      stroke="url(#paint0_linear)"
                      strokeWidth="6"
                      strokeLinecap="round"
                    />
                    <circle cx="573" cy="12" r="8" fill="url(#paint1_linear)" />
                    <circle cx="761" cy="12" r="8" fill="url(#paint1_linear)" />
                    <circle cx="9" cy="12" r="8" fill="url(#paint1_linear)" />
                    <circle cx="197" cy="12" r="8" fill="url(#paint1_linear)" />
                    <circle cx="385" cy="12" r="8" fill="url(#paint1_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="0"
                        y1="12"
                        x2="773"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset={
                            projectData.data.attributes.status_pct / 100.0 -
                            0.05
                          }
                          stopColor="#888DE0"
                        />
                        <stop
                          offset={
                            projectData.data.attributes.status_pct / 100.0 +
                            0.05
                          }
                          stopColor="#888DE0"
                          stopOpacity="0"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear"
                        x1="0"
                        y1="12"
                        x2="773"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset={
                            projectData.data.attributes.status_pct / 100.0 -
                            0.05
                          }
                          stopColor="#888DE0"
                        />
                        <stop
                          offset={
                            projectData.data.attributes.status_pct / 100.0 +
                            0.05
                          }
                          stopColor="#2F3039"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  <Box pl={2}>
                    <Typography
                      color="inherit"
                      noWrap
                      className={classes.percentage}
                    >
                      {projectData.data.attributes &&
                        `${projectData.data.attributes.status_pct}%`}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" pr={4}>
                  {projectData.included &&
                    projectData.included
                      .filter((v, i) => v.type == 'project_steps')
                      .map((v, i) => {
                        let title = `0${v.attributes.order}. ${v.attributes.title}`;
                        return (
                          <Typography
                            key={`${v.attributes.title}timelineTop`}
                            color="inherit"
                            noWrap
                            className={clsx(
                              classes.missionTitle,
                              v.attributes.completed ? classes.blueText : ''
                            )}
                          >
                            {title}
                          </Typography>
                        );
                      })}
                </Box>
              </>
            )}
          </Box>
        </div>
      </Box>
      <Box
        className={clsx(
          classes.links,
          isLowScreen ? classes.linksWithLowScreen : ''
        )}
      >
        <Box display="flex" pt={isLowScreen ? 1 : 2} justifyContent="flex-end">
          <Box
            display="flex-inline"
            flexGrow={1}
            className={isLowScreen ? classes.hideLowScreen : ''}
            maxWidth="255px"
          >
            {missionData && missionData.leads && (
              <div className={classes.search}>
                <Autocomplete
                  freeSolo
                  className={classes.seach}
                  value={selectedTarget || null}
                  getOptionLabel={({ respondent_name }) => respondent_name}
                  options={missionData.leads}
                  onChange={(event, newValue) => {
                    if (typeof newValue) {
                      handleTargetSelect(newValue);
                    }
                  }}
                  onInputChange={e => {
                    setSearchInputValue(e?.target?.value || '');
                  }}
                  open={!!searchInputValue}
                  renderInput={params => (
                    <React.Fragment>
                      <CssTextField
                        {...params}
                        label="SEARCH"
                        // color={theme.palette.common.white}
                        // margin="normal"
                        variant="outlined"
                        classes={{
                          root: classes.inputRoot
                          // input: classes.inputInput,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                      {/*<InputBase 
                        ref={params.InputProps.ref}
                        {...params}
                        placeholder="SEARCH"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{...params.inputProps}}
                        />
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>*/}
                    </React.Fragment>
                  )}
                />
              </div>
            )}
          </Box>
          <Box display="flex-inline">
            <SettingsButton handleLogout={handleLogout} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
