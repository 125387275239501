const ROOT_URL = process.env.REACT_APP_API_ROOT;

export function authHeader(isForm = false) {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  if (user && user.auth_token) {
    return isForm
      ? {
          Authorization: user.auth_token
        }
      : {
          Authorization: user.auth_token,
          'Content-Type': 'application/json'
        };
  } else {
    return {};
  }
}

const makeGETRequest = async url => {
  try {
    const res = await fetch(url, {
      headers: authHeader()
    });

    if (res.status === 401) {
      localStorage.removeItem('currentUser');
    } else if (res.status === 200) {
      return await res.json();
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchZones = async () => makeGETRequest(`${ROOT_URL}/zones`);
export const fetchZone = async id => makeGETRequest(`${ROOT_URL}/zones/${id}`);
export const fetchNciCountry = async id =>
  makeGETRequest(`${ROOT_URL}/nci_countries/${id}`);
