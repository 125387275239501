import { styled, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuthDispatch } from '../../Context';
import { clearData } from '../../Context/actions';

import './dashboard-switch.scss';

const SwitchStyled = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(26px)'
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      // color: '#33cf4d',
      // border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    backgroundColor: '#888DE0'
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: 'transparent',
    border: '2px solid #575865',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export default function DashboardSwitch({ active }) {
  const isNCIActive = active === 'nci';
  const dispatch = useAuthDispatch();
  const [checked, setChecked] = useState(!isNCIActive);
  const history = useHistory();

  return (
    <div className="dashboard-switch">
      <span className={!checked ? 'dashboard-switch--active' : ''}>NCI</span>
      <SwitchStyled
        checked={checked}
        onChange={() => {
          setChecked(!checked);
          setTimeout(() => {
            clearData(dispatch);
            return history.push(checked ? '/nci' : '/dashboard');
          }, 500);
        }}
      />
      <span className={checked ? 'dashboard-switch--active' : ''}>S</span>
    </div>
  );
}
