import React, { useState, useEffect, useRef } from 'react';
import { useHistory, matchPath } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContainer from '../CardContainer';
import RegionCountrySelector from './RegionCountrySelector';
import DashboardSwitch from '../DashboardSwitch';
import theme from '../../theme.js';

const useStyles = makeStyles(theme => ({
  drawer: {
    height: '100vh',
    overflowY: 'auto'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    // minHeight:'inier',
    height: '100vh',
    overflowY: 'auto',
    background: 'linear-gradient(#32333D, #1F2024)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  drawerPaperClose: {
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  gridDrawer: {
    height: '100%',
    flexWrap: 'nowrap',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4)
    }
  },
  button: {
    width: '100%',
    borderRadius: '2px',
    marginTop: '4px',
    padding: theme.spacing(1.25),
    borderWidth: theme.spacing(0.33)
  },
  menuButton: {
    marginRight: 0,
    marginLeft: 0
  },
  logoWrapper: {
    marginRight: '30px',

    '& img': {
      width: '170px'
    }
  },
  menuButtonHidden: {
    display: 'none'
  },
  counter: {
    fontFamily: 'DM Mono',
    fontSize: '26px',
    color: 'black'
  },
  title: {
    flexGrow: 0,
    fontSize: 16
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 700,
    paddingBottom: '0px',
    textTransform: 'uppercase'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    fontFamily: 'DM Sans'
  },
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  gridHidden: {
    opacity: 0
  },
  gridWithMargin: {
    marginBottom: theme.spacing(2)
  }
}));

const drawerWidth = 318;

export default function NCIDrawer({
  countries,
  regions,
  onChangeCountry,
  onChangeRegion,
  dropdown,
  selectedCountry,
  selectedRegion
}) {
  const classes = useStyles(theme);
  const [open, setOpen] = useState(true);
  const matches = useMediaQuery('(max-width:1650px)');
  const history = useHistory();
  const isDetailedScreen = matchPath(
    history?.location?.pathname,
    '/nci/details'
  );

  useEffect(() => {
    setOpen(!matches);
  }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
    >
      <div className={classes.toolbarIcon}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div
          className={clsx(
            classes.logoWrapper,
            !open && classes.menuButtonHidden
          )}
        >
          <img src="/CharlieLogo.svg" />
        </div>
        <IconButton
          className={clsx(
            classes.menuButton,
            !open && classes.menuButtonHidden
          )}
          onClick={handleDrawerClose}
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <Grid
        onClick={handleDrawerOpen}
        className={clsx(classes.gridDrawer, !open && classes.gridHidden)}
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        <div>
          <Box display="flex" justifyContent="center" mb={5}>
            <DashboardSwitch active="nci" />
          </Box>
          <Grid item className={classes.gridWithMargin}>
            <RegionCountrySelector
              countries={countries}
              regions={regions}
              open={open}
              onChangeCountry={onChangeCountry}
              onChangeRegion={onChangeRegion}
              dropdown={dropdown}
              selectedCountry={selectedCountry}
              selectedRegion={selectedRegion}
            >
              <Box mt={2} paddingTop="26px" borderTop="0.5px solid #595A6B">
                <Typography
                  color="textSecondary"
                  noWrap
                  className={classes.subtitle}
                >
                  Change view
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      isDetailedScreen
                        ? history.push('/nci')
                        : history.push('/nci/details')
                    }
                    className={classes.button}
                  >
                    {isDetailedScreen ? 'Active Campaigns' : 'Country Report'}
                  </Button>
                </Box>
              </Box>
            </RegionCountrySelector>
          </Grid>
        </div>
        <Grid item>
          <CardContainer smallPadding>
            <Typography
              color="textSecondary"
              noWrap
              className={classes.subtitle}
            >
              Score legend
            </Typography>
            <div className="nci-report__legend">
              <div className="nci-report__legend-item">
                <span className="nci-report__legend-color nci-report__legend-color--red" />
                <span>Poor</span>
                <span>0-33</span>
              </div>
              <div className="nci-report__legend-item">
                <span className="nci-report__legend-color nci-report__legend-color--yellow" />
                <span>Neutral</span>
                <span>34-66</span>
              </div>
              <div className="nci-report__legend-item">
                <span className="nci-report__legend-color nci-report__legend-color--green" />
                <span>Good</span>
                <span>67-100</span>
              </div>
            </div>
          </CardContainer>
        </Grid>
      </Grid>
    </Drawer>
  );
}
