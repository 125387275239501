import React, { useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../Analyze.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import theme from '../../theme.js';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loading from './Loading';
import TableGraph from '../TableGraph';
import { useAuthState } from '../../Context';
import { spacing } from '@material-ui/system';
import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Label,
  LabelList,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { useIsLowScreen } from '../../Hooks/useWindowDimensions';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  column: {
    height: 'calc(100vh - 220px)',
    maxHeight: '1280px',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  columnLowScreen: {
    height: 'calc(100vh - 150px)'
  },
  paper: {
    padding: '10px 12px 10px 32px',
    display: 'block',
    overflow: 'auto',
    overflowX: 'hidden',
    opacity: 1,

    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  type: {
    // color: theme.palette.text.secondary,
    paddingTop: '.125rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px'
    }
  },
  title: {
    // color: theme.palette.text.secondary,
    flexGrow: 1,
    paddingTop: '.125rem',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  linkIcon: {
    fontSize: '12px',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  subPage: {
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '17px',
    color: '#898AA1',
    textTransform: 'uppercase'
  },
  pageType: {
    // color: theme.palette.text.secondary,
    // paddingTop: '.125rem',
    lineHeight: '12px',
    textTransform: 'lowercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px'
    }
  },
  barPopulationLabel: {
    padding: '0 3px'
  }
}));

const ColumnHolder = ({ children }) => {
  let classes = useStyles(theme);
  const isLowScreen = useIsLowScreen();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <div
        className={clsx(
          classes.paper,
          classes.column,
          'custom-column',
          isLowScreen ? classes.columnLowScreen : ''
        )}
      >
        <Box display="flex" flexDirection="column" height={'100%'}>
          {children}
        </Box>
      </div>
    </Grid>
  );
};

const formatPercents = x => Math.floor(x * 100) / 100;

const Section = ({ title, margin = 'true', children, padding }) => {
  let classes = useStyles(theme);
  return (
    <Box mb={margin == 'true' ? 1 : 0} pb={padding == 'true' ? 2 : 0}>
      <Box mb={margin == 'true' ? 2 : 0}>
        <Typography className={classes.subtitle} color="textSecondary">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

const Stacked = ({ data, handleClick, selected }) => {
  let classes = useStyles(theme);
  return (
    <Grid item xs={12} className={clsx(classes.graph, classes.stack)}>
      <ResponsiveContainer>
        <BarChart data={data} layout="vertical">
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          {Object.keys(data).map((v, i) => (
            <Bar
              key={`${v}bar`}
              layout="vertical"
              cursor="pointer"
              dataKey={v}
              stackId="stack"
              onClick={handleClick}
              fill="transparent"
              strokeWidth={'1px'}
              stroke={i === selected ? '#888DE0' : 'white'}
            />
          ))}
          ;
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};
function dataLine(data, style, variant = 'body2') {
  return (
    // data && (
    <Typography className={style} noWrap variant={variant}>
      {data && data.length ? data : 'N/A'}
    </Typography>
    // )
  );
}
export default function Analyze() {
  let classes = useStyles(theme);
  const { projectData, missionData, missionLoading, missionErrorMessage } =
    useAuthState();
  const [demographics, setDemographics] = useState(null);

  useEffect(() => {
    setDemographics(null);
  }, [missionLoading]);

  useEffect(() => {
    if (missionData) {
      setDemographics(missionData.audience_demographics?.attributes);
    }
  }, [missionData]);

  const check = useCallback(
    item => (demographics ? demographics[item] : 'N/A'),
    [demographics]
  );

  const groupedCategories = demographics?.categories
    ? demographics?.categories.reduce((category, item) => {
        category[item.category] = category[item.category] || [];
        category[item.category].push(item);
        return category;
      }, Object.create(null))
    : [];

  if ((missionData.audience_demographics || []).length < 1) {
    return (
      <div>
        <h1>Oops!</h1>
        <p>Something went wrong.</p>
      </div>
    );
  }

  const women18_24 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_18_24_women) /
        (parseInt(missionData.audience_demographics.attributes.age_18_24_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_18_24_women
          ))) *
      100
    ).toFixed(1) + '%';
  const men18_24 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_18_24_men) /
        (parseInt(missionData.audience_demographics.attributes.age_18_24_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_18_24_women
          ))) *
      100
    ).toFixed(1) + '%';
  const women25_34 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_25_34_women) /
        (parseInt(missionData.audience_demographics.attributes.age_25_34_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_25_34_women
          ))) *
      100
    ).toFixed(1) + '%';
  const men25_34 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_25_34_men) /
        (parseInt(missionData.audience_demographics.attributes.age_25_34_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_25_34_women
          ))) *
      100
    ).toFixed(1) + '%';
  const women35_44 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_35_44_women) /
        (parseInt(missionData.audience_demographics.attributes.age_35_44_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_35_44_women
          ))) *
      100
    ).toFixed(1) + '%';
  const men35_44 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_35_44_men) /
        (parseInt(missionData.audience_demographics.attributes.age_35_44_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_35_44_women
          ))) *
      100
    ).toFixed(1) + '%';
  const women45_54 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_45_54_women) /
        (parseInt(missionData.audience_demographics.attributes.age_45_54_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_45_54_women
          ))) *
      100
    ).toFixed(1) + '%';
  const men45_54 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_45_54_men) /
        (parseInt(missionData.audience_demographics.attributes.age_45_54_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_45_54_women
          ))) *
      100
    ).toFixed(1) + '%';
  const women55_65 =
    (
      (parseInt(
        missionData.audience_demographics.attributes.age_65_plus_women
      ) /
        (parseInt(
          missionData.audience_demographics.attributes.age_65_plus_men
        ) +
          parseInt(
            missionData.audience_demographics.attributes.age_65_plus_women
          ))) *
      100
    ).toFixed(1) + '%';
  const men55_65 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_65_plus_men) /
        (parseInt(
          missionData.audience_demographics.attributes.age_65_plus_men
        ) +
          parseInt(
            missionData.audience_demographics.attributes.age_65_plus_women
          ))) *
      100
    ).toFixed(1) + '%';
  const women65 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_18_24_women) /
        (parseInt(missionData.audience_demographics.attributes.age_18_24_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_18_24_women
          ))) *
      100
    ).toFixed(1) + '%';

  const men65 =
    (
      (parseInt(missionData.audience_demographics.attributes.age_18_24_men) /
        (parseInt(missionData.audience_demographics.attributes.age_18_24_men) +
          parseInt(
            missionData.audience_demographics.attributes.age_18_24_women
          ))) *
      100
    ).toFixed(1) + '%';

  return (
    <React.Fragment>
      <ColumnHolder>
        <div id="custom-column-1">
          <div id="custom-column-1-section-1">
            <Section title="POPULATION" padding="true">
              <Grid container>
                <Grid item xs={6}>
                  <b>Size</b>{' '}
                  <Typography component="span" variant="body2" color="white">
                    {check('size')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.barPopulationLabel}
                  >
                    <Box>
                      Women {formatPercents(check('population_women'))}%
                    </Box>
                    <Box>Men {formatPercents(check('population_men'))}%</Box>
                  </Box>
                  <Box>
                    <TableGraph
                      data={[
                        {
                          category: 'men',
                          value: check('population_men')
                        },
                        {
                          category: 'women',
                          value: check('population_women')
                        }
                      ]}
                      graph={'stack'}
                      graphOnly
                    />
                  </Box>
                </Grid>
              </Grid>
            </Section>
          </div>
          {missionData.audience_demographics?.attributes && (
            <div id="custom-column-1-section-2">
              <Section title="AGE/GENDER" padding="true">
                <Grid container>
                  <Grid item xs={6}>
                    <b>18-24</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women18_24}>
                        <div
                          style={{
                            width: women18_24
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Men ' + men18_24}>
                        <div
                          style={{
                            width: men18_24
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                  <Grid item xs={6}>
                    <b>25-34</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women25_34}>
                        <div style={{ width: women25_34 }} />
                      </Tooltip>
                      <Tooltip title={'Men ' + men25_34}>
                        <div
                          style={{
                            width: men25_34
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                  <Grid item xs={6}>
                    <b>35-44</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women35_44}>
                        <div
                          style={{
                            width: women35_44
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Men ' + men35_44}>
                        <div
                          style={{
                            width: men35_44
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                  <Grid item xs={6}>
                    <b>45-54</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women45_54}>
                        <div
                          style={{
                            width: women45_54
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Men ' + men45_54}>
                        <div
                          style={{
                            width: men45_54
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                  <Grid item xs={6}>
                    <b>55-64</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women55_65}>
                        <div
                          style={{
                            width: women55_65
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Men ' + men55_65}>
                        <div
                          style={{
                            width: men55_65
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                  <Grid item xs={6}>
                    <b>65+</b>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="vertical-bar">
                      <Tooltip title={'Women ' + women65}>
                        <div
                          style={{
                            width: women65
                          }}
                        />
                      </Tooltip>

                      <Tooltip title={'Men ' + men65}>
                        <div
                          style={{
                            width: men65
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/*<Stacked data ={} />*/}
                  </Grid>
                </Grid>
              </Section>
            </div>
          )}
          <div id="custom-column-1-section-3">
            <Section title="RELATIONSHIP STATUS" padding="true">
              <Grid xs={12} sm={6} lg={12} item>
                {demographics && (
                  <TableGraph
                    // title={v.category}
                    data={[
                      {
                        category: 'Single',
                        value: demographics.status_single / 100
                      },
                      {
                        category: 'In Relationship',
                        value: demographics.status_in_relationship / 100
                      },
                      {
                        category: 'Engaged',
                        value: demographics.status_engaged / 100
                      },
                      {
                        category: 'Married',
                        value: demographics.status_married / 100
                      }
                    ]}
                    graph={'stack'}
                  />
                )}
              </Grid>
            </Section>
          </div>
          <div id="custom-column-1-section-4">
            <Section title="EDUCATION" padding="true">
              <Grid xs={12} sm={6} lg={12} item>
                {demographics && (
                  <TableGraph
                    // title={v.category}
                    data={[
                      {
                        category: 'High School',
                        value: demographics.education_high_school / 100
                      },
                      {
                        category: 'College',
                        value: demographics.education_college / 100
                      },
                      {
                        category: 'Grad School',
                        value: demographics.education_grad_school / 100
                      }
                    ]}
                    graph={'bar'}
                  />
                )}
              </Grid>
            </Section>
          </div>
          <div id="custom-column-1-section-5">
            <Section title="EMPLOYMENT" padding="true">
              <Box pr={2} style={{ maxHeight: '20vh', overflowY: 'auto' }}>
                {demographics &&
                  demographics.employment?.map((v, i) => (
                    <Box display="flex" alignItems="baseline">
                      <Typography className={classes.title} noWrap>
                        {v.job_title}
                      </Typography>
                      <Typography className={classes.type} noWrap>
                        {formatPercents(v.percentage)}%
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Section>
          </div>
        </div>
      </ColumnHolder>
      <ColumnHolder>
        <div id="custom-column-2">
          <div id="custom-column-2-section-1">
            <Section title="TOP INTEREST CATEGORIES">
              <Box pl={2}>
                {Object.keys(groupedCategories).map((key, i) => (
                  <Section title={key} margin="false" key={key + i}>
                    {groupedCategories[key].map((item, i) => (
                      <Typography variant="body2" key={i}>
                        <span>{item.name}</span>
                        <Link
                          className={classes.subtitle}
                          style={{
                            display: 'inline-block',
                            alignItems: 'center',
                            fontSize: '12px',
                            color: '#888DE0',
                            // float: 'right',
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            textTransform: 'uppercase'
                          }}
                          href={item.link}
                          target="_blank"
                        >
                          <LaunchIcon className={classes.linkIcon} />
                        </Link>
                      </Typography>
                    ))}
                  </Section>
                ))}
              </Box>
            </Section>
          </div>
          <div id="custom-column-2-section-2">
            <Section title="TOP PAGE LIKES" padding="true">
              <Box pr={2} style={{ maxHeight: '25vh', overflowY: 'auto' }}>
                {demographics &&
                  demographics.page_likes?.map((v, i) => (
                    <Box display="flex" alignItems="center">
                      {/*<Box>*/}
                      <Typography className={classes.title} variant="body2">
                        <span>{v.page_name}</span>
                        <Link
                          className={classes.subtitle}
                          style={{
                            display: 'inline-block',
                            alignItems: 'center',
                            fontSize: '12px',
                            color: '#888DE0',
                            // float: 'right',
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            textTransform: 'uppercase'
                          }}
                          href={v.link}
                          target="_blank"
                        >
                          <LaunchIcon className={classes.linkIcon} />
                        </Link>
                      </Typography>
                      {/*</Box>*/}
                      <Box
                        display="inline-flex"
                        // flexGrow={1}
                        // style={{ float: 'right' }}
                      >
                        <Box style={{ margin: '.15rem' }}>
                          <Typography className={classes.subPage}>
                            Audience
                          </Typography>
                          <Typography className={classes.pageType} noWrap>
                            <b>{v.audience}</b>
                          </Typography>
                        </Box>
                        <Box style={{ margin: '.15rem' }}>
                          <Typography className={classes.subPage}>
                            <b> Affinity</b>
                          </Typography>
                          <Typography className={classes.pageType} noWrap>
                            <b>{v.affinity}</b>
                          </Typography>
                        </Box>
                        <Box style={{ margin: '.15rem' }}>
                          <Typography className={classes.subPage}>
                            Facebook
                          </Typography>
                          <Typography className={classes.pageType} noWrap>
                            <b>{v.facebook}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Section>
          </div>
        </div>
      </ColumnHolder>
      <ColumnHolder>
        <div id="custom-column-3">
          <div id="custom-column-3-section-1">
            <Section title="LOCATIONS" padding="true">
              <Box pr={2} style={{ maxHeight: '25vh', overflowY: 'auto' }}>
                {demographics &&
                  demographics.locations?.map((v, i) => (
                    <Box display="flex" alignItems="baseline">
                      <Typography className={classes.title} noWrap>
                        {v.name}
                      </Typography>
                      <Typography className={classes.type} noWrap>
                        {formatPercents(v.audience)}%
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Section>
            <Section title="LANGUAGES" padding="true">
              {demographics &&
                demographics.languages?.map((v, i) => (
                  <Box display="flex" alignItems="baseline">
                    <Typography className={classes.title} noWrap>
                      {v.name}
                    </Typography>
                    <Typography className={classes.type} noWrap>
                      {formatPercents(v.audience)}%
                    </Typography>
                  </Box>
                ))}
            </Section>
          </div>
          <div id="custom-column-3-section-2">
            <Section title="PLATFORMS">
              <Grid xs={12} sm={6} lg={12} item>
                {demographics && (
                  <TableGraph
                    // title={v.category}
                    data={[
                      {
                        category: 'Desktop + Mobile',
                        value: demographics.platform_computer_and_mobile / 100
                      },
                      {
                        category: 'Desktop Only',
                        value: demographics.platform_computer_only / 100
                      },
                      {
                        category: 'Mobile Only',
                        value: demographics.platform_mobile_only / 100
                      }
                    ]}
                    graph={'stack'}
                  />
                )}
              </Grid>
            </Section>
          </div>
          <div id="custom-column-3-section-3">
            <Section title="DEVICES" padding="true">
              <Grid xs={12} sm={6} lg={12} item>
                {demographics && (
                  <TableGraph
                    // title={v.category}
                    data={[
                      {
                        category: 'Android',
                        value: demographics.devices_android / 100
                      },
                      {
                        category: 'Blackberry',
                        value: demographics.devices_blackberry / 100
                      },
                      {
                        category: 'Computer',
                        value: demographics.devices_computer / 100
                      },
                      {
                        category: 'Feature Phone',
                        value: demographics.devices_feature_phone / 100
                      },
                      {
                        category: 'iPad',
                        value: demographics.devices_ipad / 100
                      },
                      {
                        category: 'iPhone/iPod',
                        value: demographics.devices_iphone_ipod / 100
                      },
                      {
                        category: 'Mobile Web',
                        value: demographics.devices_mobile_web / 100
                      },
                      {
                        category: 'Unknown',
                        value: demographics.devices_unknown / 100
                      }
                    ].sort((a, b) => a - b)}
                    graph={'pie'}
                  />
                )}
              </Grid>
            </Section>
          </div>
        </div>
      </ColumnHolder>
    </React.Fragment>
  );
}
