import * as csv from 'csvtojson';

const ROOT_URL = process.env.REACT_APP_API_ROOT;
function authHeader(isForm = false) {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  if (user && user.auth_token) {
    return isForm
      ? {
          Authorization: user.auth_token
        }
      : {
          Authorization: user.auth_token,
          'Content-Type': 'application/json'
        };
  } else {
    return {};
  }
}

export function uploadForm(payload) {
  return fetch(`${ROOT_URL}/tickets`, {
    method: 'POST',
    headers: authHeader(true),
    body: payload
  });
}

export async function exportFile(currentProject) {
  const res = await fetch(`/Lebanon.xlsx`, {
    headers: authHeader()
  })
    .then(response => response.blob())
    .then(blob => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${currentProject.name}_export.xlsx`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

export function setRegion(dispatch, region) {
  dispatch({ type: 'REGION_CHANGE', payload: region });
}
export function setActor(dispatch, actor) {
  dispatch({ type: 'ACTOR_CHANGE', payload: actor });
}
export function setProject(dispatch, project) {
  dispatch({ type: 'PROJECT_CHANGE', payload: project });
}

export async function fetchProjectData(dispatch, currentProject) {
  try {
    // setLoading(true);
    dispatch({ type: 'REQUEST_PROJECT_DATA' });
    //temp way of doin this until we have real data
    const res = await fetch(`${ROOT_URL}/projects/${currentProject.id}`, {
      // const res = await fetch('/projectData.json',{
      headers: authHeader()
    });

    if (res.status === 401) {
      //TOKEN IS EXPIRED/ LOGOUT
      dispatch({ type: 'PROJECT_DATA_ERROR', error: 'Token Expired' });
      // dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('currentUser');

      // console.log(res);
    } else if (res.status === 200) {
      const dataRes = await res.json();

      console.log('DDD:', dataRes.data.type);
      const leadRes = await fetch(
        `${ROOT_URL}/projects/${currentProject.id}/${
          dataRes.data.type == 'target_projects' ? 'leads' : 'responses'
        }`,
        {
          headers: authHeader()
        }
      );
      let leads = await leadRes.json();

      dataRes.leads = leads.data ? leads.data.map((v, i) => v.attributes) : [];
      dispatch({ type: 'PROJECT_DATA_SUCCESS', payload: dataRes });
      // return
    } else {
      dispatch({ type: 'PROJECT_DATA_ERROR', error: 'Project Not Found.' });
    }
  } catch (error) {
    dispatch({ type: 'PROJECT_DATA_ERROR', error: error });
    // throw error;
  }
}

export async function fetchMissionData(
  dispatch,
  currentRegion,
  currentActor,
  currentProject,
  leads
) {
  try {
    dispatch({ type: 'REQUEST_MISSION_DATA' });
    const res = await fetch(
      `${ROOT_URL}/projects/${currentProject.id}/${currentActor.id}/${currentRegion.id}`,
      {
        headers: authHeader()
      }
    );
    const res2 = await fetch(
      `${ROOT_URL}/scout_campaigns/${currentProject.id}/${currentActor.id}/${currentRegion.id}`,
      {
        headers: authHeader()
      }
    );

    if (res.status === 401) {
      //TOKEN IS EXPIRED/ LOGOUT
      dispatch({ type: 'MISSION_DATA_ERROR', error: 'Token Expired' });
      // dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('currentUser');

    } else if (res.status === 200) {
      const dataRes = await res.json();
      const dataRes2 = await res2.json();
      console.log('HEEEERE');
      console.log(dataRes);
      console.log(dataRes2);
      console.log(currentProject);

      const data = {
        ...dataRes,
        type: currentProject.type,
        data: dataRes.data?.length
          ? currentProject.type == 'target_projects'
            ? dataRes.data[0]
            : dataRes.data
          : null
      };

      //fetch leads for target project
      // if(currentProject.type == "target_projects"){
      data.leads = leads
        ? leads.filter(v =>
            v.campaign_id ? v.campaign_id == data.data.id : true
          )
        : [];
      // }

      //fetch demographics data for psyop project
      console.log(currentProject.type == 'project');
      if (currentProject.type == 'project') {
        const demoRes = await fetch(
          `${ROOT_URL}/audience_demographics/${currentProject.id}/${currentActor.id}/${currentRegion.id}`,
          {
            headers: authHeader()
          }
        );
        if (demoRes.status === 200) {
          const demoResData = await demoRes.json();
          data.audience_demographics = demoResData.data;
        }
      }
      dispatch({ type: 'MISSION_DATA_SUCCESS', payload: data });
    } else {
      dispatch({
        type: 'MISSION_DATA_ERROR',
        error: 'Mission Data Not Found.'
      });
    }
  } catch (error) {
    dispatch({ type: 'MISSION_DATA_ERROR', error: error });
    // throw error;
  }
}

//set array of user/projectID restrictions
const restrictedUserProjects = [
  { user: 'test_user@gmail.com', projectID: 2 },
  { user: 'test_user2@gmail.com', projectID: 1 }
];

export async function fetchAllProjects(dispatch) {
  try {
    dispatch({ type: 'REQUEST_PROJECTS' });

    let res = await fetch(`${ROOT_URL}/projects`, {
      method: 'GET',
      headers: authHeader(false)
    });
    if (res.status === 401) {
      //TOKEN IS EXPIRED/ LOGOUT
      dispatch({ type: 'PROJECTS_ERROR', error: 'Token Expired' });
      // dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('currentUser');
    } else if (res.status === 200) {
      let data = await res.json();
      let user = localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser')).user
        : '';

      //dont show specific project ID to specific user
      let restrictedProjectID = restrictedUserProjects.find(
        v => v.user == user
      )?.projectID;

      let payload = data.data
        .filter(v =>
          restrictedProjectID ? true /* v.id == restrictedProjectID */ : true
        )
        .map((v, i) => {
          return { id: v.id, name: v.attributes.name, type: v.type };
        });

      dispatch({ type: 'PROJECTS_SUCCESS', payload: payload });
    } else {
      dispatch({ type: 'PROJECTS_ERROR', error: 'Mission Data Not Found.' });
    }
  } catch (error) {
    dispatch({ type: 'PROJECTS_ERROR', error: error });
  }
}

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: loginPayload }),
    redirect: 'follow'
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await fetch(`${ROOT_URL}/login`, requestOptions);
    let data = await response.json();
    if (data.data && data.data.attributes.email) {
      let token = response.headers.get('Authorization');
      let newPayload = {
        user: data.data.attributes.email,
        auth_token: token ? token : ''
      };
      localStorage.setItem('currentUser', JSON.stringify(newPayload));

      let pjResponse = await fetch(`${ROOT_URL}/projects`, {
        method: 'GET',
        headers: authHeader(false)
      });

      let pjData = await pjResponse.json();
      console.log('PJ DATA:   ', pjData);

      dispatch({ type: 'LOGIN_SUCCESS', payload: newPayload });
      return newPayload;
    }
    dispatch({ type: 'LOGIN_ERROR', error: 'User not found.' });
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function changePassword(current_password, password) {
  try {
    let response = await fetch(`${ROOT_URL}/change_password`, {
      method: 'PATCH',
      headers: {
        ...authHeader()
      },
      body: JSON.stringify({
        current_password,
        password: password,
        password_confirmation: password
      })
    });
    if (response.status === 200) {
      console.log('PASSWORD CHANGE: ', response);
      return true;
    }
    return false;
  } catch (error) {
    return false;
    console.log(error);
  }
}
export async function logout(dispatch) {
  try {
    let response = await fetch(`${ROOT_URL}/logout`, {
      method: 'DELETE',
      headers: authHeader()
    });
    console.log('LOGOUT: ', response);
  } catch (error) {
    console.log(error);
    // dispatch({ type: 'LOGIN_ERROR', error: error });
  }
  dispatch({ type: 'LOGOUT' });

  localStorage.removeItem('currentUser');
  // localStorage.removeItem('token');
}

export async function clearData(dispatch) {
  dispatch({ type: 'CLEAR_DATA' });
}
