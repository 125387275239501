import React, { useReducer } from 'react';

let user = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).user
  : '';
let token = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).auth_token
  : '';

let missionData = {};

console.log(user);
console.log(Boolean(token));

export const initialState = {
  userDetails: '' || user,
  token: '' || token,
  loading: false,
  errorMessage: null,
  allProjects: [],
  allProjectsLoading: false,
  allProjectsError: null,
  missionData: null,
  missionLoading: false,
  missionErrorMessage: null,
  projectData: null,
  projectLoading: false,
  projectErrorMessage: null,
  currentProject: null,
  currentRegion: null,
  currentActor: null
};

export const AuthReducer = (state = initialState, action) => {
  let data;
  switch (action.type) {
    case 'PROJECT_CHANGE':
      data =
        state.currentMission === action.payload
          ? {
              ...state
            }
          : {
              ...state,
              currentProject: action.payload,
              currentRegion: null,
              currentActor: null,
              missionData: null
            };
      return data;
    case 'ACTOR_CHANGE':
      data =
        state.currentActor === action.payload
          ? {
              ...state
            }
          : {
              ...state,
              currentActor: action.payload
            };
      return data;
    case 'REGION_CHANGE':
      data =
        state.currentRegion === action.payload
          ? {
              ...state
            }
          : {
              ...state,
              currentRegion: action.payload
            };
      return data;
    case 'REQUEST_MISSION_DATA':
      return {
        ...state,
        missionLoading: true
      };
    case 'MISSION_DATA_SUCCESS':
      return {
        ...state,
        missionData: action.payload,
        missionLoading: false
      };
    case 'MISSION_DATA_ERROR':
      return {
        ...state,
        missionLoading: false,
        missionErrorMessage: action.error
      };
    case 'REQUEST_PROJECT_DATA':
      return {
        ...state,
        projectLoading: true
      };
    case 'PROJECT_DATA_SUCCESS':
      return {
        ...state,
        projectData: action.payload,
        projectLoading: false
      };
    case 'PROJECT_DATA_ERROR':
      return {
        ...state,
        projectLoading: false,
        projectErrorMessage: action.error
      };
    case 'REQUEST_LOGIN':
      return {
        ...state,
        loading: true
      };
    case 'PROJECTS_SUCCESS':
      return {
        ...state,
        allProjectsLoading: false,
        allProjects: action.payload
      };
    case 'REQUEST_PROJECTS':
      return {
        ...state,
        allProjectsLoading: true
      };
    case 'PROJECTS_ERROR':
      return {
        ...state,
        allProjectsLoading: false,
        allProjectsError: action.error
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.auth_token,
        loading: false,
        allProjectsError: null,
        projectErrorMessage: null,
        missionErrorMessage: null
      };
    case 'CLEAR_DATA':
      return {
        ...initialState,
        user: state.user,
        token: state.token,
        userDetails: state.userDetails
      };
    case 'LOGOUT':
      return {
        ...state,
        user: '',
        token: ''
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
