import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import theme from '../../theme.js';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import MissionTargetCard from '../MissionTargetCard';
import CollectIcon from './icn-collecting.png';
import CompareIcon from './icn-compare.png';
import SendingIcon from './icn-sending.png';
import TableGraph from '../TableGraph';
import Loading from './Loading';
import { useAuthState } from '../../Context';
import { spacing } from '@material-ui/system';
import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Label,
  LabelList,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { useIsLowScreen } from '../../Hooks/useWindowDimensions';

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 21
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: theme.spacing(4)
  },
  column: {
    height: 'calc(100vh - 220px)',
    maxHeight: '1280px',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  columnLowScreen: {
    height: 'calc(100vh - 150px)'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'block',
    overflow: 'auto',
    overflowX: 'hidden',
    opacity: 1,

    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  respondents: {
    fontSize: 64,
    fontWeight: 'bold',
    color: '#888DE0',
    lineHeight: 1
  },
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0.125, 0.25, 0, 0),
    background: '#888DE0',
    borderRadius: theme.spacing(1)
  },
  graph: {
    top: -theme.spacing(4),
    // height:"200px",
    // top:0,
    // height:'auto'
    // marginTop:theme.spacing(2),
    // overflow:'hidden',
    marginBottom: -theme.spacing(5)
    // paddingBottom:theme.spacing(1),
    // padding:theme.spacing(0,0,0,0)
  },
  table: {
    // width:'auto'
  },
  list: {
    width: `calc(100% - ${theme.spacing(1)})`,
    height: 'inherit',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '1080px',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8)
    },
    '&>div': {
      // marginBottom:theme.spacing(2),
      // marginRight:theme.spacing(2),
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  listRow: {
    // width:'95%',
    // margin:'auto',
    padding: theme.spacing(0, 1)
  },
  rowActive: {
    opacity: 1.0,
    outline: 'solid 1px #888DE0',
    color: 'white',
    fontColor: 'white',
    transition: 'opacity .25s'
  },
  rowInactive: {
    opacity: 1.0,
    fontColor: '#595A6B',
    color: '#595A6B',
    transition: 'opacity .25s'
  },
  primary: {
    fontWeight: 700
  },
  device_holder: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '45vh'
  },
  responsiveStat: {
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  responsiveQuestion: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
    // [theme.breakpoints.down('md')]: {
    //   fontSize: '.5rem'
    // }
  },
  device: {
    position: 'relative',
    width: '100%',
    display: 'block',
    height: 'auto'
  },
  ad: {
    position: 'absolute',
    width: '100%',
    marginBottom: '10%'
  },
  item: {
    margin: theme.spacing(-0.5),
    padding: theme.spacing(1, 1, 1, 1.5),
    color: theme.palette.text.secondary,
    border: '1px solid #454654'
  },
  statParent: {
    borderTop: '1px solid #454654',
    '&>div': {
      padding: theme.spacing(1, 3),
      color: theme.palette.text.secondary,
      borderBottom: '1px solid #454654'
    },
    '&>div:nth-child(even)': {
      borderLeft: '1px solid #454654'
    }
  },
  selectedItem: {
    color: '#888DE0',
    border: '1px solid #888DE0'
  },
  linkIcon: {
    fontSize: '12px',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  graph: {
    top: theme.spacing(1),
    position: 'relative'
  },
  bar: {
    top: 0,
    // paddingTop:theme.spacing(3),
    // paddingBotton:theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      minHeight: 100
    }
  }
}));

const ColumnHolder = ({ title, subtitle, children }) => {
  let classes = useStyles(theme);
  const isLowScreen = useIsLowScreen();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <div
        className={clsx(
          classes.paper,
          classes.column,
          isLowScreen ? classes.columnLowScreen : ''
        )}
      >
        <Box display="flex" flexDirection="column" height={'100%'}>
          <Box>
            <Typography className={classes.subtitle} color="textSecondary">
              {/*<span className={classes.dot}/> */}
              {subtitle}
            </Typography>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          {children}
        </Box>
      </div>
    </Grid>
  );
};

const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

export default function Scout({ currentTarget, handleTargetSelect }) {
  let classes = useStyles(theme);
  const { projectData, missionData, missionLoading, missionErrorMessage } =
    useAuthState();

  const [targetId, setTargetId] = useState(0);
  const [selectedAnswer, setAnswer] = useState('');
  const [filteredTargets, setFilteredTargets] = useState(null);
  const [selectedAd, setSelectedAd] = useState('');
  const [stats, setStats] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [respondents, setRespondents] = useState(null);
  const [maxLimit, setMaxLimit] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const scoutCampaignAttr = missionData.data.find(
    _data => _data.attributes.type === 'ScoutCampaign'
  )?.attributes;
  const activeQuestion = scoutCampaignAttr.inline_questions_with_answers[0];
  const answerStats =
    convertArrayToObject(scoutCampaignAttr?.inline_answers_stats || [], 'id') ||
    {};
  const answerCount = (scoutCampaignAttr?.inline_answers_stats || []).reduce(
    (n, { qty }) => n + qty,
    0
  );
  const [useEnglish, setUseEnglish] = useState(
    scoutCampaignAttr.language === 'EN'
  );

  const responseData = (activeQuestion?.answers || []).map((v, i) => {
    return {
      answer: useEnglish ? v.content_english : v.content,
      id: v.id,
      value: answerStats[v.id]?.qty,
      percentage: `${((answerStats[v.id]?.qty / answerCount) * 100).toFixed(
        2
      )}%`
    };
  });

  const answerStatsLimit =
    Math.ceil(
      Math.floor(Math.max(...responseData.map((v, i) => v.value)) + 50) / 100
    ) * 100;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (missionLoading) {
      setStats(null);
      setQuestions(null);
      setFilteredTargets(null);
      setRespondents(null);
      handleTargetSelect(null);
    }
  }, [missionLoading]);

  useEffect(() => {
    setTimeout(() => {
      if (respondents) {
        if (selectedAnswer) {
          const filtered = respondents.filter(v =>
            v.responses.some(a => a.id == selectedAnswer)
          );
          setFilteredTargets(filtered);
          const shouldNotResetSubject = filtered.some(
            v => v?.psid === selectedTarget?.psid
          );
          if (!shouldNotResetSubject) {
            handleTargetSelect(undefined);
          }
        } else setFilteredTargets(respondents);
      }
      setTargetId(undefined);
    }, 0);
  }, [respondents, selectedAnswer]);

  useEffect(() => {
    if (missionData.included) {
      let tempRespondents = missionData.included
        .filter((v, i) => v.type == 'respondent')
        .map((v, i) => {
          return {
            name: v.attributes.name,
            profile_picture: v.attributes.profile_picture_url,
            responses: v.attributes.inline_responses,
            timestamp: v.attributes.last_response_time,
            psid: v.attributes.psid
          };
        });
      setRespondents(tempRespondents);
      if (missionData.audience_demographics) {
        let _stats = missionData.audience_demographics.attributes?.stats;
        setStats([
          { name: 'Spend', value: _stats.total_money_spent },
          {
            name: 'Impressions',
            value: _stats.total_impressions.toLocaleString()
          },
          { name: 'Shares', value: _stats.total_shares },
          { name: 'Comments', value: _stats.total_comments },
          { name: 'Reactions', value: _stats.total_reactions.toLocaleString() },
          { name: 'Clicks', value: _stats.total_clicks.toLocaleString() }
        ]);
      }
    }

    if (responseData[0]?.id) {
      setAnswer(responseData[0]?.id);
    }
  }, [missionData]);

  const ListRowItem = ({
    name,
    city,
    response,
    selected,
    handleClick,
    style
  }) => {
    const cardRef = useRef();

    return (
      <div ref={cardRef} style={style}>
        <ListItem
          selected={selected}
          alignItems="center"
          classes={{
            root: clsx(classes.listRow, selected ? '' : classes.rowInactive),
            selected: clsx(classes.listRow, classes.rowActive)
          }}
          button
          onClick={handleClick}
        >
          <ListItemText
            classes={{ primary: classes.primary }}
            style={{ flex: 1, fontWeight: 700 }}
            primary={name}
          />
          <ListItemText
            classes={{ primary: classes.primary }}
            style={{ flex: 1, fontWeight: 700 }}
            primary={city}
          />
          {response && (
            <ListItemText
              classes={{ primary: classes.primary }}
              style={{ flex: 1, fontWeight: 700 }}
              primary={response}
            />
          )}
        </ListItem>
      </div>
    );
  };

  let innerSvg = `<path d="M0.825073 15C0.825073 7.26801 7.09309 1 14.8251 1H332.197C339.929 1 346.197 7.26801 346.197 15V490.422H0.825073V15Z" fill="#4B4D60" stroke="#888DE0"/>
<path d="M14.7095 25.752C14.7095 20.2292 19.1867 15.752 24.7095 15.752H321.445C326.968 15.752 331.445 20.2292 331.445 25.752V490.422H14.7095V25.752Z" fill="#2E2F39"/>
<path d="M144.387 34.95H192.115" stroke="#4B4D60" stroke-width="3" stroke-linecap="round"/>
<circle cx="205.298" cy="33.7375" r="3.6375" fill="#4B4D60"/>
<image xlink:href="/Gif_V6.gif" width="290" height="412" x="28" y="62" />
`;

  let renderLabel = function (entry) {
    return <text>{entry.name}</text>;
  };

  const filteredSubjects = useMemo(
    () =>
      filteredTargets
        ? filteredTargets.filter(({ name }) =>
            currentTarget ? name == currentTarget.respondent_name : true
          )
        : [],
    [currentTarget, filteredTargets]
  );

  const selectedTarget = useMemo(() => {
    if (filteredTargets) {
      if (currentTarget) {
        return filteredTargets.find(
          ({ psid }) => psid == currentTarget.respondent_psid
        );
      }
      return targetId
        ? filteredTargets.find(({ psid }) => psid == targetId)
        : filteredTargets[0];
    }
    return null;
  }, [targetId, filteredTargets, currentTarget]);

  const renderRespondentRow = React.useCallback(
    ({ id, style }) => {
      const selectedRespondent = filteredTargets.find(({ psid }) => psid == id);
      return (
        filteredTargets && (
          <ListRowItem
            handleClick={() => {
              setTargetId(id);
            }}
            // activeColumn={activeColumn}
            name={selectedRespondent.name}
            city={selectedRespondent.city}
            key={`Row${id}`}
            // response={filteredTargets[index]['Response']}
            selected={id == selectedTarget?.psid}
            style={style}
          />
        )
      );
    },
    [filteredTargets, selectedTarget]
  );

  const renderCustomizedLabel = useCallback(
    props => {
      const { x, y, width, height, value, index } = props;
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + height - 16}
            fill={selectedAnswer == responseData[index].id ? '#888DE0' : '#fff'}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: 18,
              fontWeight: 800
            }}
          >
            {value}
          </text>
        </g>
      );
    },
    [selectedAnswer, responseData]
  );

  const Stats = useMemo(() => {
    return (
      (scoutCampaignAttr?.inline_questions_with_answers || []).length > 0 && (
        <Box
          display="flex"
          height="inherit"
          mt={3}
          style={{
            // flexGrow: 0
            maxHeight: '720px',
            minHeight: '20%'
          }}
        >
          <ResponsiveContainer
            width="99%"
            // height="66%"
            minHeight="100"
            debounce={0}
          >
            <BarChart
              width={'inherit'}
              height={300}
              data={responseData}
              interval={0}
              margin={{ top: 20, right: 0, left: -20, bottom: 10 }}
            >
              <CartesianGrid
                stroke="#454654"
                strokeDasharray=""
                vertical={false}
              />
              <YAxis
                domain={[0, answerStatsLimit]}
                dataKey="value"
                axisLine={false}
                tickLine={false}
                padding={{ top: 0, bottom: 0, left: 0 }}
              />
              <Bar
                // data={responseData}
                dataKey="value"
                nameKey="answer"
                stroke="#8884d8"
                maxBarSize={100}
                fill="none"
                interval={0}
                // label={renderLabel}
                // label={entry => entry}

                // label={{
                //   stroke: 'none',
                //   fill: 'white',
                //   fontSize: 18,
                //   fontWeight: 800
                // }}
              >
                <LabelList
                  dataKey="value"
                  nameKey="answer"
                  data={responseData}
                  // content={props => renderLabel(props, "pv")}
                  content={props => renderCustomizedLabel(props)}
                  position="insideBottom"
                />
                {activeQuestion.answers.map((entry, index) => (
                  <Cell
                    onClick={() => {
                      setAnswer(entry.id);
                    }}
                    key={`${entry}cell`}
                    cursor="pointer"
                    fill={'transparent'}
                    stroke={entry.id === selectedAnswer ? '#888DE0' : 'white'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )
    );
  }, [activeQuestion, selectedAnswer]);

  if (
    (scoutCampaignAttr?.inline_questions_with_answers || []).length < 1 ||
    (respondents || []).length < 1
  ) {
    return (
      <div>
        <h1>Oops!</h1>
        <p>Something went wrong.</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ColumnHolder title="" subtitle="RESULTS">
        {respondents && (
          <Box display="inline-flex" alignItems="baseline">
            <Typography className={classes.respondents}>
              {projectData.leads.length}
            </Typography>
            <Box display="div" pl={2}>
              <Typography className={classes.subtitle} color="textSecondary">
                Survey Respondents
              </Typography>
            </Box>
          </Box>
        )}
        <Box display="div" my={2}>
          <Divider />
        </Box>
        <Box pb={2} display="flex">
          <Box flexGrow={1}>
            <Typography className={classes.subtitle} color="textSecondary">
              Survey Question
            </Typography>
          </Box>
          <Box>
            <Box onClick={handleClick} style={{ float: 'right' }}>
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                  fontSize: '12px'
                }}
                className={classes.subtitle}
                color="textSecondary"
              >
                {useEnglish
                  ? 'English'
                  : languageNames.of(scoutCampaignAttr.language) ||
                    scoutCampaignAttr.language}
                <ArrowDropDownIcon />
              </Typography>
            </Box>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {scoutCampaignAttr.language !== 'EN' && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setUseEnglish(false);
                  }}
                >
                  {languageNames.of(scoutCampaignAttr.language) ||
                    scoutCampaignAttr.language}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  handleClose();
                  setUseEnglish(true);
                }}
              >
                English
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {activeQuestion && (
          <Box px={0}>
            <Typography className={classes.responsiveQuestion}>
              {useEnglish
                ? activeQuestion.content_english
                : activeQuestion.content}
            </Typography>
            <Link
              className={classes.subtitle}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                color: '#888DE0',
                float: 'right',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                textTransform: 'uppercase'
              }}
              href="https://facebook.com/103714604834996/posts/317992386740549"
              target="_blank"
            >
              <p>permalink</p> <LaunchIcon className={classes.linkIcon} />
            </Link>
            <Grid container spacing={3}>
              {activeQuestion.answers.map((v, i) => (
                <Grid
                  key={`${v.id}answer`}
                  item
                  xs={6}
                  onClick={() => {
                    setAnswer(v.id);
                  }}
                >
                  <div
                    className={clsx(
                      classes.item,
                      v.id == selectedAnswer ? classes.selectedItem : ''
                    )}
                  >
                    <Box display="inline-flex" alignItems="baseline">
                      <Box mr={1}>
                        <Typography
                          // variant="h4"
                          className={classes.responsiveStat}
                          component="div"
                          style={{ fontWeight: 800 }}
                          color={
                            selectedAnswer == v.id ? 'initial' : 'textPrimary'
                          }
                        >
                          {answerStats[v.id]?.qty || 0}
                        </Typography>
                      </Box>
                      <Typography variant="body1" color="textPrimary">
                        {((answerStats[v.id]?.qty / answerCount) * 100).toFixed(
                          1
                        )}
                        %
                      </Typography>
                    </Box>
                    <Typography
                      noWrap={true}
                      variant="body2"
                      color="textSecondary"
                    >
                      {useEnglish ? v.content_english : v.content}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {activeQuestion && Stats}
      </ColumnHolder>
      <ColumnHolder title="" subtitle="CAMPAIGN STATS">
        <Box
          className={classes.device_holder}
          position="inline"
          display="flex"
          mx={0}
        >
          <svg
            dangerouslySetInnerHTML={{
              __html: innerSvg.replace(
                'placeholder.png',
                'placeholder.png'
                //   // process.env.PUBLIC_URL + 'adPlaceholder.png'
                //   // missionData.mission.narrative.find((v, i) => {
                //   //   return selectedAd.length
                //   //     ? v.category == selectedAd
                //   //     : i == 0;
                //   // }).ad
              )
            }}
            width="100%"
            height="100%"
            viewBox="0 0 347 491"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          />
        </Box>
        <Grid className={classes.statParent} container spacing={0}>
          {stats &&
            stats.map((v, i) => (
              <Grid item xs={6} key={`${v.value}stats`}>
                <Typography
                  // variant="h4"
                  className={classes.responsiveStat}
                  style={{ fontWeight: 800, color: '#888DE0' }}
                >
                  {v.value}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {v.name}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </ColumnHolder>
      <ColumnHolder title="" subtitle="SUBJECTS">
        {filteredSubjects?.length > 0 && (
          <>
            <Box mb={2}>
              {filteredTargets && selectedTarget && (
                <MissionTargetCard target={selectedTarget} />
              )}
            </Box>
            <List className={classes.list} dense={true}>
              <Box px={1} pr={6}>
                {filteredSubjects.map((v, i) => {
                  return renderRespondentRow({ id: v.psid });
                })}
              </Box>
            </List>
          </>
        )}
      </ColumnHolder>
    </React.Fragment>
  );
}
