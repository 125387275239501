import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TargetDetailCard from '../TargetDetailCard';
import TableGraph from '../TableGraph';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuthState } from '../../Context';

// import { List } from 'react-virtualized';
// import { useVirtual } from "react-virtual";

// import {AutoSizer} from 'react-virtualized';
// import {List as VirtList} from 'react-virtualized';
// import { VariableSizeList as FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import theme from '../../theme.js';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 21
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },

  mission_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',

    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px' // width/height of the corners (or 40px)
  },
  fixedHeight: {
    height: '360px'
  },
  fadeOut: {
    opacity: '0!important',
    willChange: 'opacity'
    // transition:'opacity .25s'
  },
  fadeIn: {
    opacity: '1!important',
    willChange: 'opacity'
    // transition:'opacity .25s'
  },
  lastList: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    padding: theme.spacing(0, 4),
    maxHeight: '1080px',

    height: 'calc(100vh - 190px)',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      overflow: 'visible'
    },

    '&>div': {
      // maxWidth:'340px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  listRow: {
    width: '95%',
    margin: 'auto',
    // margin:theme.spacing(2),
    padding: theme.spacing(0, 1)
  },
  list: {
    width: '100%',
    padding: '0px',
    opacity: 0,
    // padding:theme.spacing(2,2,2,0),

    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '1080px',
    height: 'calc(100vh - 190px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(50vh - 190px)',
      minHeight: '280px',
      marginBottom: theme.spacing(8)
    },
    '&>div': {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  rowActive: {
    opacity: 1.0,
    outline: 'solid 1px #888DE0',
    color: 'white',
    fontColor: 'white',
    transition: 'opacity .25s'
  },
  rowInactive: {
    opacity: 1.0,
    fontColor: '#595A6B',

    color: '#595A6B',
    transition: 'opacity .25s'
  }
}));

function useSmoothScroll() {
  const listOuterRef = useRef(null);

  const scrollTo = useCallback(scrollOffset => {
    listOuterRef.current?.scrollTo({
      left: 0,
      top: scrollOffset - listOuterRef.current?.offsetHeight / 2,
      behavior: 'smooth'
    });
  }, []);
  return {
    scrollableRef: listOuterRef,
    scrollTo
  };
}

export default function MissionStats({
  handleTargetSelect,
  selectedTarget,
  active
}) {
  const classes = useStyles(theme);
  const { missionData, missionLoading, missionErrorMessage } = useAuthState(); //read user details from context

  const [targetIndex, setTargetIndex] = useState(0);
  const [nameList, setNameList] = useState(null);
  const [activeColumn, setActiveColumn] = useState('');
  const { scrollableRef, scrollTo } = useSmoothScroll();
  const [sortedDemographics, setSortedDemographics] = useState(null);

  const handleRowClick = _index => {
    setTargetIndex(_index);
  };

  useEffect(() => {
    if (missionLoading) {
      setNameList(null);
      setSortedDemographics(null);
    }
  }, [missionLoading]);

  useEffect(() => {
    if (missionData) {
      if (missionData.leads) {
        setNameList(
          missionData.leads.map(function (v) {
            return v.full_name;
          })
        );
        setTargetIndex(0);
      }
      if (missionData.included) {
        let i = missionData.included
          .filter((v, i) => v.type == 'campaign_demographics_data')
          .reduce((acc, val) => {
            if (!acc[val.attributes.category])
              acc[val.attributes.category] = {
                category: val.attributes.category,
                data: []
              };
            acc[val.attributes.category].data.push(val.attributes);
            return acc;
          }, {});
        let sortData = [];
        for (const [key, value] of Object.entries(i)) {
          sortData.push(value);
        }
        setSortedDemographics(sortData);
      }
    }
  }, [missionData]);

  useEffect(() => {
    if (nameList && selectedTarget) {
      let i = nameList.indexOf(selectedTarget);
      setTargetIndex(i);
      setActiveColumn('search');
    }
  }, [selectedTarget, nameList]);

  const cardSize = 282;

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (activeColumn !== 'card') {
      scrollTo(
        matches
          ? (targetIndex + 0.5) * cardSize + theme.spacing(1)
          : (targetIndex + 0.5) * cardSize
      );
    }
  }, [targetIndex, matches]);

  const ListRowItem = ({
    activeColumn,
    name,
    city,
    selected,
    handleClick,
    style
  }) => {
    const cardRef = useRef();
    useEffect(() => {
      if (selected && activeColumn !== 'list') {
        if (activeColumn === 'search') {
          cardRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
        } else {
          cardRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    }, [selected]);
    return (
      <div ref={cardRef} style={style}>
        <ListItem
          selected={selected}
          alignItems="center"
          classes={{
            root: clsx(classes.listRow, selected ? '' : classes.rowInactive),
            selected: clsx(classes.listRow, classes.rowActive)
          }}
          button
          onClick={handleClick}
        >
          <ListItemText style={{ flex: 1 }} primary={name} />
          <ListItemText style={{ flex: 1 }} primary={city} />
        </ListItem>
      </div>
    );
  };

  const renderRow = React.useCallback(
    ({ index, style }) => {
      return (
        missionData &&
        missionData.leads &&
        missionData.leads.length && (
          <ListRowItem
            handleClick={() => {
              setActiveColumn('list');
              handleRowClick(index);
            }}
            activeColumn={activeColumn}
            name={missionData.leads[index].full_name}
            city={missionData.leads[index].city}
            key={`${missionData.leads[index].full_name}Row${index}`}
            selected={index === targetIndex}
            style={style}
          />
        )
      );
    },
    [targetIndex, missionData, activeColumn]
  );

  const renderTargetCard = React.useCallback(
    ({ index, style }) => {
      return (
        missionData &&
        missionData.leads &&
        missionData.leads.length && (
          <TargetDetailCard
            activeColumn={activeColumn}
            handleClick={() => {
              setActiveColumn('card');
              // handleTargetSelect(data.targets[index]['Full Name']);
              handleRowClick(index);
            }}
            key={`${missionData.leads[index].full_name}Card${index}`}
            target={missionData.leads[index]}
            size={cardSize}
            selected={index === targetIndex}
            style={style}
          />
        )
      );
    },
    [missionData, targetIndex, handleTargetSelect, activeColumn]
  );

  return (
    <Grid
      container
      spacing={0}
      className={active ? classes.fadeIn : classes.fadeOut}
    >
      <Grid item xs={12} md={6} lg={4} style={{ minHeight: '262px' }}>
        {missionData && missionData.leads && (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                className={clsx(
                  missionLoading ? classes.fadeOut : classes.fadeIn,
                  classes.list
                )}
                height={height}
                itemCount={missionData.leads.length}
                itemSize={cardSize}
                width={width}
                outerRef={scrollableRef}
              >
                {missionData && renderTargetCard}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {
          <List
            className={clsx(
              missionLoading ? classes.fadeOut : classes.fadeIn,
              classes.list
            )}
            dense={true}
          >
            <Box px={3} my={1}>
              {missionData &&
                missionData.leads &&
                missionData.leads.map((v, i) => {
                  return renderRow({ index: i });
                })}
            </Box>
          </List>
        }
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box justifyContent="space-between" className={classes.lastList}>
          <Typography color="textSecondary" noWrap className={classes.subtitle}>
            AUDIENCE
          </Typography>
          <Typography color="inherit" noWrap className={classes.title}>
            Demographics
          </Typography>
          <Grid container mb={-4} spacing={2}>
            {sortedDemographics &&
              sortedDemographics.map((v, i) => (
                <Grid key={`${v.category}graph`} xs={12} sm={6} lg={12} item>
                  <TableGraph
                    title={v.category}
                    data={v.data
                      .filter((v, i) => v.visible)
                      .map(v => {
                        return { category: v.name, value: v.prevalence };
                      })}
                    graph={
                      v.category == 'User Devices'
                        ? 'pie'
                        : v.category == 'Platform'
                        ? 'stack'
                        : 'bar'
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
