import React from 'react';
import SVGMap from './SvgMap';
import Map from './world_map.json';

import './styles/react-svg-map.scss';

export function getLocationName(event) {
  return event.target.attributes.name.value;
}

// map countries props to {name,value}
class ReactSvgMap extends React.Component {
  constructor(props) {
    super(props);

    this.handleLocationFocus = this.handleLocationFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedCountry !== this.props.selectedCountry &&
      this.props.selectedCountry
    ) {
      const path = document.querySelector(
        `path[name="${this.props.selectedCountry}"]`
      );
      if (path) {
        path.remove();
        const svg = document.querySelector('svg.svg-map');
        svg.appendChild(path);
      }
    }
  }

  handleLocationFocus(event) {
    const focusedLocation = getLocationName(event);
    if (focusedLocation && this.props.onSelectCountry) {
      this.props.onSelectCountry(focusedLocation);
    }
  }

  render() {
    return (
      <SVGMap
        map={Map}
        onLocationFocus={this.handleLocationFocus}
        isLocationSelected={path => {
          if (path.name) {
            return this.props.selectedCountry == path.name;
          }
          return false;
        }}
        countries={this.props.countries || []}
        selectedCountry={this.props.selectedCountry}
        cropSingleCountry={this.props.cropSingleCountry}
      />
    );
  }
}

ReactSvgMap.defaultProps = {
  cropSingleCountry: true
};

export default ReactSvgMap;
