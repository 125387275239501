import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from '../theme.js';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from 'recharts';

const useStyles = makeStyles(theme => ({
  table: {
    // width:'inherit',
    tableLayout: 'fixed',
    width: `calc(100% + ${theme.spacing(3)}px )`
  },
  cell: {
    borderBottom: 'none',
    padding: '.125rem 0rem 0rem 0rem',
    // paddingLeft:"0",
    '&:last-child': {
      padding: '0rem'
    },
    overflow: 'auto'
  },
  selected: {
    '& *': {
      color: '#888DE0'
    }
  },
  bold: {
    fontWeight: 700,
    // width:"20%"
    width: '80px'
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  subtitle: {
    fontSize: 14,
    // fontWeight:700,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  graph: {
    top: theme.spacing(1),
    position: 'relative'
  },
  bar: {
    top: 0,
    // paddingTop:theme.spacing(3),
    // paddingBotton:theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      minHeight: 100
    }
  },
  stack: {
    top: 0,
    height: 100,
    // height:'auto'
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    marginBottom: -theme.spacing(6)
    // paddingTop:theme.spacing(2),
    // paddingBottom:theme.spacing(1)
  },
  catergory: {
    paddingRight: 0
  }
}));

export default function TableGraph({
  data,
  title,
  graph,
  subtitle,
  clickHandler = null,
  graphStyle = null,
  tableStyle = null,
  graphOnly = false
}) {
  const classes = useStyles(theme);

  let i = data
    ? data
        .sort((a, b) => b.value - a.value)
        .map((v, i) => {
          return { value: v.value * 100, name: v.category };
        })
    : [];

  let stackedData = i.map((v, _i) => {
    let o = {};
    o[`value${_i}`] = v.value;
    return o;
  });
  stackedData = [stackedData.reduce((r, c) => Object.assign(r, c), {})];

  const [selected, setSelected] = useState(0);

  const handleClick = (data, index) => {
    if (index == selected) {
      setSelected(-1);
      if (clickHandler) clickHandler('');
    } else {
      setSelected(index);
      if (clickHandler) clickHandler(data.name);
    }
  };

  return (
    <React.Fragment>
      <Grid container style={{ overflow: 'hidden' }}>
        <Grid item xs={12}>
          <Box width={'50%'}>
            {title && (
              <Typography color="inherit" className={classes.title}>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                color="inherit"
                className={classes.subtitle}
                onClick={() => handleClick({ name: '' }, -1)}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Grid>
        {!graphOnly && (
          <>
            <Grid
              item
              xs={graph === 'stack' ? 12 : 6}
              sm={graph === 'stack' ? 12 : 6}
            >
              <TableContainer
                style={{
                  top: '4px',
                  position: 'relative',
                  overflowX: 'visible'
                }}
              >
                <Table
                  className={clsx(classes.table, tableStyle)}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {data
                      .sort((a, b) => b.value - a.value)
                      .map((row, index) => (
                        <TableRow
                          key={row.category}
                          className={selected === index ? classes.selected : ''}
                          onClick={() =>
                            handleClick({ name: row.category }, index)
                          }
                        >
                          <TableCell
                            align="left"
                            className={clsx(classes.cell, classes.bold)}
                          >{`${parseFloat(
                            (row.value * 100).toFixed(2)
                          ).toString()}%`}</TableCell>
                          <TableCell align="left" className={classes.cell}>
                            <Typography color="inherit" noWrap variant="body2">
                              {row.category}{' '}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        {graph === 'pie' && (
          <Grid item xs={6} sm={6} className={clsx(classes.graph, graphStyle)}>
            <ResponsiveContainer>
              <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <Pie
                  data={i}
                  fill="#8884d8"
                  dataKey="value"
                  onClick={handleClick}
                >
                  {data
                    .sort((a, b) => b.value - a.value)
                    .map((entry, index) => (
                      <Cell
                        cursor="pointer"
                        style={{ zIndex: index === selected ? '9' : '-1' }}
                        key={`cell-${index}`}
                        strokeWidth={'1px'}
                        stroke={index === selected ? '#888DE0' : 'white'}
                        fill={'transparent'}
                      />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {graph === 'bar' && (
          <Grid item xs={6} sm={6} className={clsx(classes.graph, classes.bar)}>
            <ResponsiveContainer>
              <BarChart
                margin={{
                  top: 0,
                  left: theme.spacing(4),
                  right: theme.spacing(1),
                  bottom: 0
                }}
                data={i}
              >
                <Bar dataKey="value" onClick={handleClick}>
                  {data
                    .sort((a, b) => b.value - a.value)
                    .map((entry, index) => (
                      <Cell
                        key={`${entry}cell`}
                        cursor="pointer"
                        fill={'transparent'}
                        stroke={index === selected ? '#888DE0' : 'white'}
                      />
                    ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {graph === 'stack' && (
          <Grid item xs={12} className={clsx(classes.graph, classes.stack)}>
            <ResponsiveContainer>
              <BarChart data={stackedData} layout="vertical">
                <XAxis type="number" hide />
                <YAxis type="category" hide />
                {Object.keys(stackedData[0]).map((v, i) => (
                  <Bar
                    barSize={10}
                    key={`${v}bar`}
                    layout="vertical"
                    cursor="pointer"
                    dataKey={v}
                    stackId="stack"
                    onClick={handleClick}
                    fill="transparent"
                    strokeWidth={'1px'}
                    stroke={i === selected ? '#888DE0' : 'white'}
                  />
                ))}
                ;
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
