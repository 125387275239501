import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardContainer from '../CardContainer';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Slider from '@material-ui/core/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';
import theme from '../../theme.js';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 21,
    '&::before': {
      border: 'none'
    },
    whiteSpace: 'normal',
    minWidth: 100
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 700,
    paddingBottom: '0px'
  },

  media: {
    // height: 0,
    // borderLeft:'solid 1px #575865',
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    padding: theme.spacing(0),
    // width: `calc(100% + ${2*theme.spacing(3)}px)`, // twice the value of the parent's padding
    // marginLeft: - theme.spacing(3), // -1 * parent's padding
    pointerEvents: 'none',
    zIndex: -10,
    color: 'linear-gradient(#e66465, #9198e5)',
    height: '100%'
    // marginLeft:theme.spacing(2),
    // marginRight:-theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500],
    width: 28,
    height: 28,
    flex: '0 0 auto',
    marginRight: '16px'
  },
  divider: {
    marginTop: '8px',
    marginBottom: '8px'
    // paddingLeft:'0px',
    // paddingRight:'0px'
  },
  cardHeader: {
    padding: '0px',
    // paddingTop:'2px',
    // paddingBottom:'2px',
    '& .MuiCardHeader-action': {
      margin: 0,
      '& button': {
        padding: '8px'
      }
    },
    '& .MuiCardHeader-title': {
      fontSize: 21
    },
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  formControl: {
    '& label': {
      textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px)!important'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 30px)'
    }
  },
  up: {
    marginTop: '-100px'
  },
  hide: {
    opacity: 0
    // display: 'none'
    // color: 'yellow',
    // content: ''
  }
}));

const Region = ({ selected, regions, onChangeRegion }) => {
  const classes = useStyles(theme);

  const handleChange = event => {
    const region = _.find(regions, { id: event.target.value });
    onChangeRegion(region);
  };

  const defaultOption = regions && (selected || regions[0]);

  return (
    <React.Fragment>
      <CardContent className={classes.cardHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel id="region-select-label">
            <Typography className={classes.subtitle} color="textSecondary">
              Region
            </Typography>
          </InputLabel>
          <Box className={classes.menuItem} pt={2}>
            {defaultOption && (
              <React.Fragment>
                <Avatar
                  aria-label="region"
                  src={defaultOption.flag || '/africom_flag.png'}
                  className={classes.avatar}
                />
                <Select
                  labelId="region-select-label"
                  id="region-select"
                  value={defaultOption?.id}
                  onChange={handleChange}
                  className={classes.title}
                >
                  {regions &&
                    regions.map((v, i) => (
                      <MenuItem key={`${v.id}menuItem`} value={v.id}>
                        {_.upperCase(v.name)}
                      </MenuItem>
                    ))}
                </Select>
              </React.Fragment>
            )}
          </Box>
        </FormControl>
      </CardContent>
      <Box
        py={2}
        display="block"
        style={{
          overflow: 'hidden',
          maxWidth: '15vh',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        {defaultOption?.globe && (
          <CardMedia
            component="img"
            className={classes.media}
            image={defaultOption.globe}
            title={`${defaultOption.name} Map`}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

const Country = ({ countries, onChangeCountry, selected }) => {
  const classes = useStyles(theme);

  const handleChange = event => {
    onChangeCountry(event.target.value ?? null);
  };

  const defaultOption = countries && (selected || countries[0]);

  return (
    <React.Fragment>
      <CardContent className={classes.cardHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel id="actor-select-label">
            <Typography className={classes.subtitle} color="textSecondary">
              Country
            </Typography>
          </InputLabel>
          <Box className={classes.menuItem} pt={2}>
            {defaultOption && (
              <React.Fragment>
                {/*<Avatar*/}
                {/*  aria-label="country"*/}
                {/*  src="/nigeria_flag.png"*/}
                {/*  className={classes.avatar}*/}
                {/*/>*/}
                <Select
                  labelId="actor-select-label"
                  id="actor-select"
                  value={defaultOption.actor_id}
                  onChange={handleChange}
                  className={classes.title}
                >
                  {countries &&
                    countries.map((v, i) => (
                      <MenuItem
                        key={`${v.actor_id}menuItem`}
                        value={v.actor_id}
                      >
                        {v.actor_name}
                      </MenuItem>
                    ))}
                </Select>
              </React.Fragment>
            )}
          </Box>
        </FormControl>
      </CardContent>
    </React.Fragment>
  );
};

export default function RegionCountrySelector({
  regions,
  countries,
  onChangeCountry,
  onChangeRegion,
  children,
  selectedCountry,
  selectedRegion
}) {
  return (
    <CardContainer smallPadding nowrap>
      <Region
        onChangeRegion={onChangeRegion}
        regions={regions || []}
        selected={selectedRegion}
      />
      <Country
        countries={countries || []}
        selected={selectedCountry}
        onChangeCountry={onChangeCountry}
      />
      {children}
    </CardContainer>
  );
}
