import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const ColorLine = [
  { value: 80, color: '#68CC58' },
  { value: 66, color: '#AAE2A2' },
  { value: 50, color: '#FFDC85' },
  { value: 40, color: '#FFD15C' },
  { value: 33, color: '#FFC32A' },
  { value: 18, color: '#DB6B7A' },
  { value: -1, color: '#D14154' }
];

const SVGMap = props => {
  const { countries, cropSingleCountry } = props;
  const arrayOfCountries = countries.map(country => country.name);
  const isSingleRegion = cropSingleCountry && arrayOfCountries.length < 2;

  useEffect(() => {
    const svg = document.querySelector('svg.svg-map');

    const { xMin, xMax, yMin, yMax } = [...svg.children].reduce((acc, el) => {
      const { x, y, width, height } = el.getBBox();
      if (!arrayOfCountries.includes(el.getAttribute('name'))) {
        return acc;
      }
      if (!acc.xMin || x < acc.xMin) acc.xMin = x;
      if (!acc.xMax || x + width > acc.xMax) acc.xMax = x + width;
      if (!acc.yMin || y < acc.yMin) acc.yMin = y;
      if (!acc.yMax || y + height > acc.yMax) acc.yMax = y + height;
      return acc;
    }, {});

    let addSpace = 100;
    if (isSingleRegion) {
      addSpace = 10;
    }
    const viewbox = `${xMin - addSpace / 2} ${
      yMin - (isSingleRegion ? addSpace : addSpace / 4)
    } ${xMax - xMin + addSpace} ${
      yMax - yMin + (isSingleRegion ? addSpace * 2 : addSpace / 2)
    }`;

    svg.setAttribute('viewBox', viewbox);
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={props.map.viewBox}
      className={`${props.className} ${
        isSingleRegion ? props.className + '--single-region' : ''
      }`}
      role={props.role}
      aria-label={props.map.label}
    >
      {props.childrenBefore}
      {props.map.locations.map((location, index) => {
        const isSelectable = arrayOfCountries.includes(location.name);
        const color =
          isSelectable &&
          ColorLine.find(
            ({ value }) =>
              value <
              countries.find(country => country.name == location.name).value
          );
        return (
          <path
            id={location.id}
            name={location.name}
            d={location.path}
            fill={color ? color.color : null}
            className={`${
              typeof props.locationClassName === 'function'
                ? props.locationClassName(location, index)
                : props.locationClassName
            } ${isSelectable ? 'svg-map--selectable' : ''}`}
            tabIndex={
              typeof props.locationTabIndex === 'function'
                ? props.locationTabIndex(location, index)
                : props.locationTabIndex
            }
            role={props.locationRole}
            aria-label={
              typeof props.locationAriaLabel === 'function'
                ? props.locationAriaLabel(location, index)
                : location.name
            }
            aria-checked={
              props.isLocationSelected &&
              props.isLocationSelected(location, index)
            }
            onMouseOver={props.onLocationMouseOver}
            onMouseOut={props.onLocationMouseOut}
            onMouseMove={props.onLocationMouseMove}
            onClick={props.onLocationClick}
            onKeyDown={props.onLocationKeyDown}
            onFocus={isSelectable ? props.onLocationFocus : () => {}}
            onBlur={props.onLocationBlur}
            key={location.id}
          />
        );
      })}
      {props.childrenAfter}
    </svg>
  );
};

SVGMap.propTypes = {
  // Map properties
  map: PropTypes.shape({
    viewBox: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string
      })
    ).isRequired,
    label: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  role: PropTypes.string,

  // Locations properties
  locationClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  locationTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  locationRole: PropTypes.string,
  locationAriaLabel: PropTypes.func,
  onLocationMouseOver: PropTypes.func,
  onLocationMouseOut: PropTypes.func,
  onLocationMouseMove: PropTypes.func,
  onLocationClick: PropTypes.func,
  onLocationKeyDown: PropTypes.func,
  onLocationFocus: PropTypes.func,
  onLocationBlur: PropTypes.func,
  isLocationSelected: PropTypes.func,

  // Slots
  childrenBefore: PropTypes.node,
  childrenAfter: PropTypes.node
};

SVGMap.defaultProps = {
  className: 'svg-map',
  role: 'none', // No role for map
  locationClassName: 'svg-map__location',
  locationTabIndex: '0',
  locationRole: 'none'
};

export default SVGMap;
