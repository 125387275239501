// Config/routes.js

import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';
import NCIDashboard from '../Pages/NCIDashboard';
import NotFound from '../Pages/NotFound';

const routes = [
  {
    path: '/login',
    component: Login,
    isPrivate: false
  },
  {
    path: '/dashboard',
    component: Dashboard,
    isPrivate: true
  },
  {
    path: '/nci',
    component: NCIDashboard,
    isPrivate: true
  },
  {
    path: '/',
    component: Login,
    isPrivate: false
  },
  {
    path: '/*',
    component: NotFound,
    isPrivate: true
  }
];

export default routes;
