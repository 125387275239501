import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import theme from '../theme.js';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Fade } from '@material-ui/core';

import LoadingIndicator from '../Components/LoadingIndicator';
import TopToolbar from '../Components/TopToolbar';
import TargetMission from '../Components/Target';
import PsyopCampaign from '../Components/Psyop';
import MissionDetail from '../Components/MissionDetail';

import Drawer from '../Components/Drawer';
import {
  useAuthDispatch,
  logout,
  fetchAllProjects,
  fetchMissionData,
  fetchProjectData,
  setRegion,
  setActor,
  useAuthState
} from '../Context';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    position: 'relative',
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  mission_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  background: {
    background: 'linear-gradient(180deg, #383945 0%, #212229 100%)',
    backgroundSize: 'cover'
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      overflowY: 'hidden'
    }
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& > div': {
      color: 'white'
    }
    // opacity:1,
    // transition: "opacity .5s",
    // transitionDelay: '1s'
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity .25s',
    transitionDelay: '0s'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity .05s',
    transitionDelay: '.15s'
  }
}));

export default function Dashboard(props) {
  // const { loading, data } = useFetch("/data.json");
  const classes = useStyles(theme);
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const {
    allProjects,
    allProjectsLoading,
    allProjectsError,
    projectData,
    projectLoading,
    projectErrorMessage,
    missionData,
    missionLoading,
    missionErrorMessage,
    currentRegion,
    currentActor,
    currentProject
  } = useAuthState(); //read user details from context

  const missionRef = useRef();

  const [currentTarget, setCurrentTarget] = useState(null);
  const [drawerDropdown, setDrawerDropdown] = useState(null);

  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  //loading all Projects
  useEffect(() => {
    async function fetchProjects() {
      let response = await fetchAllProjects(dispatch);
    }
    fetchProjects();
  }, []);

  useEffect(() => {
    async function fetchProject() {
      if (currentProject) {
        let response = await fetchProjectData(dispatch, currentProject);
      }
    }
    fetchProject();
    setCurrentTarget(null);
  }, [currentProject]);

  useEffect(() => {
    async function fetchMission() {
      let response = await fetchMissionData(
        dispatch,
        currentRegion,
        currentActor,
        currentProject,
        projectData.leads
      );
    }
    if (currentRegion && currentActor && currentProject) {
      fetchMission();
    }
  }, [currentRegion, currentActor, currentProject]);

  useEffect(() => {
    // if(missionData) console.log("MISSIONDATA:", missionData);
  }, [missionData]);
  useEffect(() => {
    if (projectData) console.log('PROJECTDATA:', projectData);
  }, [projectData]);

  //error handling, logout on expired tokens

  useEffect(() => {
    if (missionErrorMessage && missionErrorMessage == 'Token Expired') {
      console.log('DATA ERROR MESSAGE: ', missionErrorMessage);
      handleLogout();
    }
  }, [missionErrorMessage]);

  useEffect(() => {
    if (projectErrorMessage && projectErrorMessage == 'Token Expired') {
      console.log('DATA ERROR MESSAGE: ', projectErrorMessage);
      handleLogout();
    }
  }, [projectErrorMessage]);

  useEffect(() => {
    if (allProjectsError && allProjectsError == 'Token Expired') {
      console.log('DATA ERROR MESSAGE: ', allProjectsError);
      handleLogout();
    }
  }, [allProjectsError]);

  const handleLogout = () => {
    logout(dispatch);
    props.history.push('/login');
  };

  const handleSelectTarget = v => {
    setCurrentTarget(v);
  };
  const handleOpenDetail = () => {
    setDetailOpen(true);
    // if(missionRef.current)missionRef.current.openDetail();
  };

  const [detailOpen, setDetailOpen] = useState(false);

  const handleCloseDetail = () => {
    setDetailOpen(false);
  };

  return (
    <React.Fragment>
      <Box
        className={clsx(
          projectLoading || missionLoading ? classes.fadeIn : classes.fadeOut,
          classes.loading
        )}
      >
        <LoadingIndicator />
      </Box>
      <Drawer dropdown={drawerDropdown} />
      <main className={clsx(classes.content, classes.background)}>
        <Container maxWidth="xl" className={classes.mission_container}>
          {
            <TopToolbar
              handleTargetSelect={handleSelectTarget}
              selectedTarget={currentTarget}
              handleDetailOpen={handleOpenDetail}
              handleLogout={handleLogout}
            />
          }
        </Container>
        {missionData && (
          <Container maxWidth="xl" className={classes.container}>
            {missionData.type == 'target_projects' ? (
              <TargetMission
                ref={missionRef}
                handleTargetSelect={handleSelectTarget}
                currentTarget={currentTarget}
                handleLogout={handleLogout}
                detailOpen={detailOpen}
              />
            ) : (
              <PsyopCampaign
                active={!detailOpen}
                handleSetDrawerDropdown={setDrawerDropdown}
                handleTargetSelect={handleSelectTarget}
                currentTarget={currentTarget}
              />
            )}
            {detailOpen && projectData && (
              <MissionDetail
                data={projectData}
                handleClose={handleCloseDetail}
              />
            )}
          </Container>
        )}
      </main>
    </React.Fragment>
  );
}
