import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';

import Typography from '@material-ui/core/Typography';

import theme from '../theme.js';

const useStyles = makeStyles(theme => ({
  root: {
    // width/height of the corners (or 40px)
  },
  paper: {
    paddingBottom: theme.spacing(2),
    display: 'grid',
    overflow: 'auto',
    // opacity:.25,
    transition: 'opacity .25s',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer'
  },

  fixedHeight: {
    // minHeight: '240px',
    // [theme.breakpoints.down('sm')]: {
    //    minHeight: '140px',
    // },
    //  [theme.breakpoints.down('lg')]: {
    //    minHeight: '240px',
    // },
  },
  img: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      // maxWidth:'100px'
      // width: '75px',
      // display:'none'
    }
  },
  active: {
    opacity: 1.0,
    transition: 'opacity .25s',
    '&:hover': {
      opacity: 1.0
    }
  },
  type: {
    paddingTop: '.125rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px'
    }
  },
  type: {
    // color: theme.palette.text.secondary,
    paddingTop: '.125rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px'
    }
  },
  title: {
    // color: theme.palette.text.secondary,
    flexGrow: 1,
    paddingTop: '.125rem',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  empty: {
    fontWeight: 700
  },
  card: {
    padding: theme.spacing(3),
    background: '#595A6B',
    borderRadius: '0px',
    height: '100%',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
    // filter: 'dropShadow(0px 8px 18px rgba(0, 0, 0, 0.12))'
  },
  cardActive: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  }
}));
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function dataLine(data, style, variant = 'body2') {
  return (
    // data && (
    <Typography className={style} noWrap variant={variant}>
      {data && data.length ? data : 'N/A'}
    </Typography>
    // )
  );
}

export default function MissionTargetCard({ target }) {
  const classes = useStyles(theme);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let formatted_date = isValidDate(new Date(target.timestamp))
    ? format(new Date(target.timestamp), 'yyyy-MM-dd HH:mm')
    : null;
  return (
    <Box p={1.5} pt={0}>
      <Box className={clsx(fixedHeightPaper, classes.active)}>
        <Paper className={clsx(classes.card, classes.cardActive)}>
          <Grid container spacing={2}>
            <Grid xs={3} md={4} item>
              <img
                className={classes.img}
                src={
                  target.profile_picture
                    ? target.profile_picture
                    : 'default.jpeg'
                }
              />
            </Grid>
            <Grid xs={9} md={8} className={classes.type} item>
              {dataLine(target.name, classes.empty, 'body1')}
              {/*{dataLine(`Response: ${target['Response']}`, classes.type)}*/}
              <Box display="flex" alignItems="baseline">
                {dataLine('psid', classes.title)}
                {dataLine(target.psid, classes.type)}
              </Box>
              <Box display="flex" alignItems="baseline">
                {dataLine('last active', classes.title)}
                {dataLine(formatted_date+ ' UTC', classes.type)}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}
