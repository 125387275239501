import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './Config/routes.js';
import { AuthProvider } from './Context';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme.js';
import AppRoute from './Components/AppRoute';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    background: 'linear-gradient(#383945, #212229)',
    '& *': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#535193 #313864',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
        webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
        background: '#383945'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255,.5)',
        borderRadius: '.25rem',
        width: '.5rem'
      }
    }
  },

  background: {
    background: 'url(/background.png) no-repeat center center fixed',
    backgroundSize: 'cover'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  }
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <AuthProvider>
          <Router>
            <Switch>
              {routes.map(route => (
                <AppRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  isPrivate={route.isPrivate}
                />
              ))}
            </Switch>
          </Router>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
