import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 21
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  desc: {
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: '0px',
    '& a': {
      color: '#898AA1',
      textDecoration: 'none'
    },
    '& a:hover': {
      opacity: 0.8
    }
  },
  column: {
    height: 'calc(100vh - 120px)',
    maxHeight: '1280px',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  paper: {
    padding: '16px',
    display: 'block',
    overflow: 'auto',
    overflowX: 'hidden',
    opacity: 1,

    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  noHorizontalPadding: {
    padding: '16px 0'
  }
}));

const ColumnHolder = ({
  title,
  subtitle,
  desc,
  children,
  gridProps,
  noHorizontalPadding
}) => {
  let classes = useStyles(theme);

  return (
    <Grid item xs={12} md={12} lg={12} {...gridProps}>
      <div
        className={clsx(
          classes.paper,
          classes.column,
          noHorizontalPadding ? classes.noHorizontalPadding : ''
        )}
      >
        <Box display="flex" flexDirection="column" height={'100%'}>
          <Box padding={noHorizontalPadding ? '0 16px' : ''}>
            <Typography className={classes.subtitle} color="textSecondary">
              {subtitle}
            </Typography>
            {title && (
              <Typography className={classes.title}>{title}</Typography>
            )}
            {desc && (
              <Typography className={classes.desc} color="textSecondary">
                {desc}
              </Typography>
            )}
          </Box>
          {children}
        </Box>
      </div>
    </Grid>
  );
};

export default ColumnHolder;
