import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import theme from '../../theme.js';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import { useAuthState } from '../../Context';
import LinkIcon from './icn-link.png';
import BtnPlay from './btn-play.png';
import { ReactComponent as Arrow } from './arrow.svg';
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  XAxis,
  LineChart,
  YAxis,
  LabelList
} from 'recharts';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 21
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  componentTitle: {
    fontSize: 16,
    fontWeight: 400
  },
  column: {
    height: 'calc(100vh - 220px)'
  },

  paper: {
    padding: theme.spacing(2),
    display: 'block',
    overflow: 'auto',
    overflowX: 'hidden',
    opacity: 1,

    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  },
  bigNumber: {
    fontSize: 64,
    fontWeight: 'bold',
    color: '#888DE0'
  },
  totalNumber: {
    fontSize: 118,
    fontWeight: 'bold',
    color: '#888DE0'
  },
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0.125, 0.25, 0, 0),
    background: '#888DE0',
    borderRadius: theme.spacing(1)
  },
  graph: {
    top: -theme.spacing(4),
    marginBottom: -theme.spacing(5)
  },
  table: {
    // width:'auto'
  },
  listItem: {
    padding: '15px',
    display: 'flex',
    border: '1px solid #575865',
    marginTop: '15px'
  },
  listItemLabel: {
    display: 'flex',
    flex: 1,
    color: '#FFFFFF'
  },
  listItemValue: {
    color: '#888DE0',
    fontWeight: 700,
    paddingLeft: '5px'
  },
  listItemIndex: {
    color: '#898AA1',
    paddingRight: '10px'
  },
  listItemActive: {
    borderColor: '#888DE0'
  },
  link: {
    color: '#888DE0',
    fontWeight: 700,
    paddingRight: 10
  },
  webinarTitle: {
    fontSize: '21px',
    marginTop: '20px',
    marginBottom: '8px'
  },
  video: {
    position: 'relative',

    '& video': {
      borderRadius: '12px'
    }
  },
  item: {
    padding: theme.spacing(1, 3),
    color: theme.palette.text.secondary,
    border: '1px solid #454654'
  },
  selectedItem: {
    color: '#888DE0',
    border: '1px solid #888DE0'
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  arrowUpActive: {
    '& path': {
      fill: '#8EDE40'
    }
  },
  arrowDown: {
    transform: 'rotate(180deg)'
  },
  arrowDownActive: {
    '& path': {
      fill: '#EE1C25'
    }
  }
}));

const questions = [
  {
    value: 3,
    id: 1,
    label: 'Where did the money go?'
  },
  {
    value: 1.2,
    id: 2,
    label: 'Hezbollah (State Within a State)?'
  },
  {
    value: 0.2,
    id: 3,
    label: 'Hezbollah (State Within a State)?'
  },
  {
    value: -2,
    id: 4,
    label: 'Hezbollah (State Within a State)?'
  },
  {
    value: -3,
    id: 5,
    label: 'Hezbollah (State Within a State)?'
  }
];

const ColumnHolder = ({ title, subtitle, children, gridProps }) => {
  let classes = useStyles(theme);

  return (
    <Grid item xs={12} md={12} lg={4} {...gridProps}>
      <div className={clsx(classes.paper, classes.column)}>
        <Box display="flex" flexDirection="column" height={'100%'}>
          <Box>
            <Typography className={classes.subtitle} color="textSecondary">
              {subtitle}
            </Typography>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          {children}
        </Box>
      </div>
    </Grid>
  );
};

const formatPercents = value =>
  Math.floor(value) == 0 ? `.${value * 10}` : `${value}`;

export default function Message() {
  let classes = useStyles(theme);
  const { projectData, missionData, missionLoading, missionErrorMessage } =
    useAuthState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTarget, setTarget] = useState(123);
  const [selectedQuestion, setQuestion] = useState(1);
  const questionDetails = questions.find(({ id }) => id == selectedQuestion);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  React.useEffect(() => {
    setIsVideoPlaying(false);
  }, [selectedQuestion]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomizedLabel = props => {
    const { x, y, value, offset, index } = props;
    const isFirst = index === 0;
    const isLast = offset === index;

    return (
      (isFirst || isLast) && (
        <text
          x={x}
          y={y}
          dy={4}
          dx={isLast ? 15 : -15}
          fill={'white'}
          fontSize={16}
          textAnchor={isLast ? 'start' : 'end'}
          fontFamily="DM Sans"
          fontWeight="700"
        >
          {formatPercents(value)}%
        </text>
      )
    );
  };

  const Details = (
    <ColumnHolder
      title=""
      subtitle="WEBINAR"
      gridProps={{
        lg: 8,
        md: 12
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
      >
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box mr={4} display="flex" flexDirection="column">
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.webinarTitle}
              >
                {questionDetails.label}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Video (2:56)
              </Typography>
              <Box mb="8px" onClick={() => {}}>
                <Typography variant="caption" className={classes.link}>
                  PERMALINK
                </Typography>
                <img src={LinkIcon} />
              </Box>
              <Typography variant="body2" color="textSecondary">
                Sentiment Change
              </Typography>
              <Typography variant="h2" className={classes.bigNumber}>
                {formatPercents(questionDetails.value)}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box pt="25px" display="flex" flexDirection="column">
              <Box className={classes.video}>
                <ReactPlayer
                  controls={isVideoPlaying}
                  height="auto"
                  playing={isVideoPlaying}
                  url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  width="100%"
                />
                {!isVideoPlaying && (
                  <img
                    onClick={() => setIsVideoPlaying(true)}
                    src={BtnPlay}
                    className={classes.playBtn}
                  />
                )}
              </Box>
              <Box marginTop={2} display="flex">
                {questions.map(({ id }) => (
                  <Box
                    key={id}
                    onClick={() => setQuestion(id)}
                    flex={1}
                    height={6}
                    bgcolor={id == selectedQuestion ? '#888DE0' : '#575865'}
                    margin={1}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box display="div" my={2}>
            <Divider />
          </Box>
          <Grid spacing={4} container>
            <Grid item xs={12} md={6}>
              <Typography
                className={classes.componentTitle}
                color="textSecondary"
              >
                Sentiment Change per Video
              </Typography>
              <Box mt={2}>
                <ResponsiveContainer width="100%" height={150}>
                  <LineChart
                    margin={{ top: 0, right: 70, left: 70, bottom: 0 }}
                  >
                    <CartesianGrid horizontal={false} strokeWidth={0.2} />
                    <XAxis
                      dataKey="category"
                      type="category"
                      allowDuplicatedCategory={false}
                      strokeWidth={0.5}
                      tickSize={15}
                    />
                    <YAxis dataKey="value" domain={[18.6, 29]} hide />
                    <Line
                      dataKey="value"
                      data={[
                        { category: '0', value: 18.6 },
                        { category: '1', value: 22 },
                        { category: '2', value: 24 },
                        { category: '3', value: 22 },
                        { category: '4', value: 25 },
                        { category: '5', value: 27 }
                      ]}
                      name="Name"
                      stroke="#888DE0"
                      strokeWidth="5px"
                      dot={{
                        r: 5,
                        strokeWidth: '2px',
                        stroke: '#32333D',
                        fill: '#888DE0'
                      }}
                    >
                      <LabelList content={<CustomizedLabel />} />
                    </Line>
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={classes.componentTitle}
                color="textSecondary"
              >
                Total Sentiment Change
              </Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="h2" className={classes.totalNumber}>
                  13.2%
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Box
                    className={clsx(
                      classes.arrowUp,
                      true ? classes.arrowUpActive : ''
                    )}
                  >
                    <Arrow />
                  </Box>
                  <Box
                    className={clsx(
                      classes.arrowDown,
                      false ? classes.arrowDownActive : ''
                    )}
                  >
                    <Arrow />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ColumnHolder>
  );

  return (
    questionDetails && (
      <React.Fragment>
        <ColumnHolder title="" subtitle="MESSAGES">
          <Box marginTop="15px" display="flex" alignItems="center">
            <Typography variant="h2" className={classes.bigNumber}>
              1001
            </Typography>
            <Box flex={1} display="flex" flexDirection="column" pl={2}>
              <Box>
                <Box onClick={handleClick} style={{ float: 'right' }}>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      fontWeight: 700
                    }}
                    className={classes.componentTitle}
                    color="textSecondary"
                  >
                    Language
                    <ArrowDropDownIcon />
                  </Typography>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>English</MenuItem>
                </Menu>
              </Box>
              <Box flexGrow={1}>
                <Typography
                  variant="body2"
                  className={classes.componentTitle}
                  color="textSecondary"
                >
                  Survey Responses
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="div" my={2}>
            <Divider />
          </Box>
          <Typography className={classes.componentTitle} color="textSecondary">
            Target Opinion
          </Typography>
          <Box mt={2}>
            <Grid container spacing={3}>
              {new Array(4)
                .fill({ value: 123, label: 'Unites States', percent: 16.8 })
                .map(({ value, label, percent }, index) => {
                  const fakeValue = value + index;
                  const isSelected = fakeValue == selectedTarget;

                  return (
                    <Grid
                      key={value}
                      item
                      xs={6}
                      onClick={() => {
                        setTarget(fakeValue);
                      }}
                    >
                      <div
                        className={clsx(
                          classes.item,
                          isSelected ? classes.selectedItem : ''
                        )}
                      >
                        <Box display="inline-flex" alignItems="baseline">
                          <Box mr={1}>
                            <Typography
                              variant="h4"
                              component="div"
                              style={{ fontWeight: 800 }}
                              color={isSelected ? '#888DE0' : 'textPrimary'}
                            >
                              {value}
                            </Typography>
                          </Box>
                          <Typography variant="body1" color="textPrimary">
                            {formatPercents(percent)}%
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {label}
                        </Typography>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>

          <Box mt={3} display="flex" flexDirection="column">
            <Typography
              className={classes.componentTitle}
              color="textSecondary"
            >
              Narrative Testing (5)
            </Typography>
            {questions.map(({ id, value, label }, index) => {
              const isSelected = id == selectedQuestion;
              return (
                <Box
                  className={clsx(
                    classes.listItem,
                    isSelected && classes.listItemActive
                  )}
                  key={id}
                  onClick={() => setQuestion(id)}
                >
                  <Typography className={classes.listItemIndex}>
                    {index + 1}.
                  </Typography>
                  <Typography className={classes.listItemLabel}>
                    {label}
                  </Typography>
                  <Typography
                    className={classes.listItemValue}
                    color="textSecondary"
                  >
                    {value > 0 && '+'}
                    {formatPercents(value)}%
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </ColumnHolder>
        {Details}
      </React.Fragment>
    )
  );
}
