import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardContainer from './CardContainer';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Slider from '@material-ui/core/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';
import theme from '../theme.js';

// import SVG from 'react-inlinesvg';
import {
  setRegion as setRegionContext,
  setActor as setActorContext,
  useAuthState,
  useAuthDispatch
} from '../Context';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    '&::before': {
      border: 'none'
    },
    whiteSpace: 'normal',
    minWidth: 100
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
    // transform:'none'
  },

  media: {
    // height: 0,
    // borderLeft:'solid 1px #575865',
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    padding: theme.spacing(0),
    // width: `calc(100% + ${2*theme.spacing(3)}px)`, // twice the value of the parent's padding
    // marginLeft: - theme.spacing(3), // -1 * parent's padding
    pointerEvents: 'none',
    zIndex: -10,
    color: 'linear-gradient(#e66465, #9198e5)',
    height: '100%'
    // marginLeft:theme.spacing(2),
    // marginRight:-theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500],
    width: 28,
    height: 28,
    flex: '0 0 auto',
    marginRight: '16px'
  },
  divider: {
    marginTop: '8px',
    marginBottom: '8px'
    // paddingLeft:'0px',
    // paddingRight:'0px'
  },
  cardHeader: {
    padding: '0px',
    // paddingTop:'2px',
    // paddingBottom:'2px',
    '& .MuiCardHeader-action': {
      margin: 0,
      '& button': {
        padding: '8px'
      }
    },
    '& .MuiCardHeader-title': {
      fontSize: 21
    },
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  formControl: {
    '& label': {
      textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px)!important'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 30px)'
    }
  },
  up: {
    marginTop: '-100px'
  },
  hide: {
    opacity: 0
    // display: 'none'
    // color: 'yellow',
    // content: ''
  }
}));

const CustomSlider = withStyles({
  root: {
    color: '#595A6B',
    height: 1,
    padding: '13px 0'
    // position:'relative',
    // transform:'translateX(-120%)'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#2b2d32',
    border: '1px solid #595A6B',
    borderRadius: '0px',
    marginTop: -8,
    marginLeft: -8,
    // boxShadow: '#ebebeb 0 2px 2px',
    // '&:focus, &:hover, &$active': {
    //   boxShadow: '#ccc 0 2px 3px 1px',
    // },
    '& .bar': {
      // display: inline-block !important;
      height: 1,
      width: 1,
      backgroundColor: '#595A6B',
      marginLeft: 1,
      marginRight: 1
    }
  },
  active: {},
  track: {
    height: 1
  },
  rail: {
    color: '#595A6B',
    opacity: 1,
    height: 1
  }
})(Slider);

//temp solution to cloud assets
const findFlag = src => {
  let str = src ? src.match('InfluenceFlags-(.*?).png') : null;
  return str ? str[0] : '';
};

const findMap = src => {
  let str = src ? src.match('InfluenceMaps-(.*?).svg') : null;
  return str ? str[0] : '';
};

const Region = ({ regions }) => {
  const classes = useStyles(theme);
  const {
    projectLoading,
    currentRegion,
    currentActor,
    projectData,
    missionData
  } = useAuthState(); //read user details from context
  const dispatch = useAuthDispatch(); // read dispatch method from context

  const [region, setRegion] = React.useState(currentRegion);
  const [scale, setScale] = React.useState(1.0);

  const handleChange = event => {
    // setRegion(event.target.value);
    setRegion(regions.find(v => v.attributes.name == event.target.value));
  };

  // useEffect(()=>{
  //   if (projectData && projectData.missions && projectData.missions.length){
  //     setRegion(projectData.missions[0].region.split(' ').join('_').toLowerCase());
  //   }

  // },[projectData]);

  useEffect(() => {
    if (regions && regions.length) {
      setRegion(regions[0]);
    } else {
      setRegionContext(dispatch, null);
      setRegion(null);
    }
  }, [regions]);

  useEffect(() => {
    setRegionContext(dispatch, region);
    setScale(1.0);
  }, [region]);

  const handleSliderChange = (event, newValue) => {
    setScale(newValue);
  };
  return (
    <React.Fragment>
      <CardContent className={classes.cardHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel id="region-select-label">
            <Typography className={classes.subtitle} color="textSecondary">
              Region
            </Typography>
          </InputLabel>
          <Box className={classes.menuItem} pt={2}>
            {!projectLoading && region && regions && (
              <React.Fragment>
                <Avatar
                  aria-label="region"
                  src={region.attributes.direct_flag_url}
                  // src={`data:image/png;base64,${region.attributes?.encoded_flag?.data}`}
                  className={classes.avatar}
                ></Avatar>
                <Select
                  labelId="region-select-label"
                  id="region-select"
                  value={region.attributes ? region.attributes.name : ''}
                  onChange={handleChange}
                  className={classes.title}
                >
                  {regions &&
                  regions.map((v, i) => (
                    <MenuItem
                      key={`${v.attributes.name}menuItem`}
                      value={v.attributes.name}
                    >
                      {v.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </React.Fragment>
            )}
          </Box>
        </FormControl>
      </CardContent>
      {/*} <div style={{transform:`scale(${scale})`, fill:'blue'}}>
        <SVG
        className={classes.media}
          // baseURL="/home"
          cacheRequests={true}
          description="The React logo"
          // loader={<span>Loading...</span>}
          // onError={(error) => console.log(error.message)}
          // onLoad={(src, hasCache) => console.log(src, hasCache)}
          // preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
          preProcessor={(code)=> code.replace(/fill=".*?"/g, 'fill="url(#grad1)"').replace('</svg>', `<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
      <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
    </linearGradient></svg>`)}
          src="/svgs/us.svg"
          title="React"
          // uniqueHash="a1f8d1"
          // uniquifyIDs={true}
        />
        </div>*/}
      {region && (
        <Box
          py={2}
          display="block"
          style={{
            overflow: 'hidden',
            maxWidth: '15vh',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <CardMedia
            component="img"
            className={classes.media}
            image={region?.attributes?.direct_map_url}
            // image={`data:image/svg+xml;base64,${region?.attributes?.encoded_map?.data}`}
            title={`${region.attributes.name} Map`}
            style={{ transform: `scale(${scale})` }}
          />
        </Box>
      )}
      {region && region?.attributes?.encoded_map && (
        <CustomSlider
          defaultValue={1.5}
          onChange={handleSliderChange}
          min={0.5}
          max={2.5}
          step={0.001}
          aria-label="Map Zoom Thumb"
        />
      )}
    </React.Fragment>
  );
};

const Actor = ({ actors }) => {
  const classes = useStyles(theme);
  const {
    projectLoading,
    currentRegion,
    currentActor,
    projectData,
    missionData
  } = useAuthState();
  const dispatch = useAuthDispatch();

  const [actor, setActor] = React.useState(currentActor);

  const handleChange = event => {
    setActor(actors.find(v => v.attributes.name == event.target.value));
  };

  const handleChangeX = event => {
    // setActor(actors.find(v => v.attributes.name == event.target.value));
  };

  let targeting =
    missionData?.audience_demographics?.attributes?.targeting === undefined
      ? ''
      : missionData?.audience_demographics?.attributes?.targeting;
  const rawHtml = `<div class="MuiInputBase-root MuiInput-root makeStyles-title-188 MuiInputBase-formControl MuiInput-formControl"><div class="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input" tabindex="0" role="button" aria-haspopup="listbox" aria-labelledby="actor-select-labelx actor-selectx" id="actor-selectx">${targeting}</div><input aria-hidden="true" tabindex="-1" class="MuiSelect-nativeInput" value="Lebanese Citizens who &quot;Don't Know&quot; who to blame for the current economic situation"><svg class="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg></div>`;

  useEffect(() => {
    if (actors && actors.length) {
      setActor(actors[0]);
    } else {
      setActor(null);
    }
  }, [actors]);

  useEffect(() => {
    setActorContext(dispatch, actor);
  }, [actor]);

  return (
    <React.Fragment>
      <CardContent className={classes.cardHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel id="actor-select-label">
            <Typography className={classes.subtitle} color="textSecondary">
              Actor
            </Typography>
          </InputLabel>
          <Box className={classes.menuItem} pt={2}>
            {!projectLoading && actor && actors && (
              <React.Fragment>
                <Avatar
                  aria-label="region"
                  src={actor.attributes.direct_flag_url}
                  // src={actor.attributes.encoded_flag?`data:image/png;base64,${actor.attributes.encoded_flag?.data}`:''}
                  className={classes.avatar}
                ></Avatar>
                <Select
                  labelId="actor-select-label"
                  id="actor-select"
                  value={actor.attributes ? actor.attributes.name : ''}
                  onChange={handleChange}
                  className={classes.title}
                >
                  {actors &&
                  actors.map((v, i) => (
                    <MenuItem
                      key={`${v.attributes.name}menuItem`}
                      value={v.attributes.name}
                    >
                      {v.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </React.Fragment>
            )}
          </Box>
        </FormControl>
      </CardContent>
    </React.Fragment>
  );
};

const Dropdown = ({ dropdown }) => {
  const classes = useStyles(theme);
  const [value, setValue] = useState('');
  useEffect(() => {
    if (dropdown) {
      setValue(dropdown);
    }
  }, [dropdown]);
  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Box className="targeting-group" mt={2}>
      <CardContent className={classes.cardHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel id="actor-select-label">
            <Typography className={classes.subtitle} color="textSecondary">
              Targeting
            </Typography>
          </InputLabel>
          <Box className={classes.menuItem} mt={-2.5}>
            <Select
              labelId="target-select-label"
              id="target-select"
              value={value}
              onChange={handleChange}
              classes={{
                select: classes.hide
              }}
              className={classes.title}
            >
              {dropdown && (
                <MenuItem key={`${dropdown}targetDropdown`} value={dropdown}>
                  {dropdown}
                </MenuItem>
              )}
            </Select>
          </Box>
        </FormControl>
      </CardContent>
      <Box display="block">
        <Typography
          style={{ whiteSpace: 'normal' }}
          varient="body1"
          color="textPrimary"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default function RegionActorSelector({ dropdown }) {
  const { projectData, projectLoading, currentRegion } = useAuthState();

  const [regions, setRegions] = useState(null);
  const [actors, setActors] = useState(null);
  const [regionActorPairs, setRegionActorPairs] = useState([]);

  //figure out missions//

  useEffect(() => {
    if (projectData && projectData.included) {
      let campaigns = projectData.included.filter(v => v.type == 'campaign');
      let scout_campaigns = projectData.included.filter(
        v => v.type == 'scout_campaign'
      );
      let _regions = projectData.included.filter(v => v.type == 'region');
      let _actors = projectData.included.filter(v => v.type == 'actor');
      campaigns = campaigns.concat(scout_campaigns);
      setRegionActorPairs(
        campaigns.map((v, i) => {
          return {
            region: _regions.find(
              _v => _v.id == v.relationships.region.data.id
            ),
            actor: _actors.find(_v => _v.id == v.relationships.actor.data.id)
          };
        })
      );
    }
  }, [projectData]);

  //current setting actors based on regions/ might need to be reversed
  useEffect(() => {
    if (regionActorPairs.length) {
      setRegions([...new Set(regionActorPairs.map((v, i) => v.region))]);
    }
  }, [regionActorPairs]);

  useEffect(() => {
    if (projectLoading) {
      setRegionActorPairs([]);
      setRegions([]);
      setActors([]);
    }
  }, [projectLoading]);

  useEffect(() => {
    if (currentRegion) {
      setActors([
        ...new Set(
          regionActorPairs
            .filter(v => v.region.id == currentRegion.id)
            .map((v, i) => v.actor)
        )
      ]);
    }
  }, [currentRegion]);

  return (
    <CardContainer smallPadding nowrap>
      <Region regions={regions} />
      <Actor actors={actors} />
      {dropdown && <Dropdown dropdown={dropdown} />}
    </CardContainer>
  );
}
