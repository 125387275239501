import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import theme from '../../theme.js';
import clsx from 'clsx';
import { useAuthState } from '../../Context';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 21
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  componentTitle: {
    fontSize: 16,
    fontWeight: 400
  },
  column: {
    height: 'calc(100vh - 220px)'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'block',
    overflow: 'auto',
    overflowX: 'hidden',
    opacity: 1,

    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    scrollbarWidth: 'thin',
    scrollbarColor: '#535193 #313864',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
      background: '#383945'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '.25rem',
      width: '.5rem'
    }
  }
}));

const ColumnHolder = ({ title, subtitle, children, gridProps }) => {
  let classes = useStyles(theme);

  return (
    <Grid item xs={12} md={12} lg={12} {...gridProps}>
      <div className={clsx(classes.paper, classes.column)}>
        <Box display="flex" flexDirection="column" height={'100%'}>
          <Box>
            <Typography className={classes.subtitle} color="textSecondary">
              {subtitle}
            </Typography>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          {children}
        </Box>
      </div>
    </Grid>
  );
};

const formatPercents = value =>
  Math.floor(value) == 0 ? `.${value * 10}` : `${value}`;

export default function Amplify() {
  let classes = useStyles(theme);
  const { projectData, missionData, missionLoading, missionErrorMessage } =
    useAuthState();

  return (
    <ColumnHolder
      title="Narrative Distribution"
      subtitle="AMPLIFY"
    ></ColumnHolder>
  );
}
