import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { spacing } from '@material-ui/system';
import Typography from '@material-ui/core/Typography';
import Tooltip from './OverflowTooltip';
import theme from '../theme.js';
import defaultProfile from './default.jpeg';

const useStyles = makeStyles(theme => ({
  root: {
    // width/height of the corners (or 40px)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'grid',
    overflow: 'auto',
    opacity: 0.25,
    transition: 'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource:
      'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)

    '&:hover': {
      opacity: 0.5,
      transition: 'opacity .25s'
    }
  },

  fixedHeight: {
    // minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '140px'
    }
    //  [theme.breakpoints.down('lg')]: {
    //    minHeight: '240px',
    // },
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      // maxWidth:'100px'
      // width: '75px',
      // display:'none'
    }
  },
  active: {
    opacity: 1.0,
    transition: 'opacity .25s',
    '&:hover': {
      opacity: 1.0
    }
  },
  innerType: {
    // [theme.breakpoints.down('lg')]: {
    fontSize: '10px',
    paddingTop: '.3rem',
    // },
    height: '100%'
    // fontSize:'12px',
    // paddingTop:'.3rem',
  },
  type: {
    // display:'grid',
    width: '100%',
    overflow: 'hidden',
    paddingLeft: '.5rem'
    // paddingTop:'.5rem',
  },
  empty: {},
  card: {
    width: '100%',
    overflow: 'hidden',
    padding: theme.spacing(4),
    background: '#595A6B',
    borderRadius: '0px',
    height: '100%',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
    // filter: 'dropShadow(0px 8px 18px rgba(0, 0, 0, 0.12))'
  },
  cardActive: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  }
}));
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function dataLine(data, style, variant = 'body2') {
  return (
    data && (
      <Grid item>
        <Tooltip title={data} style={style} noWrap variant={variant} />
      </Grid>
    )
  );
}

export default function TargetDetailCard({
  selected,
  style,
  target,
  handleClick,
  size
}) {
  const classes = useStyles(theme);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let formatted_date = isValidDate(new Date(target.Date))
    ? format(new Date(target.Date), 'MM/dd/yy Hmma')
    : null;
  return (
    <Box px={1.5} pb={3} style={style} onClick={handleClick}>
      <Box
        className={clsx(fixedHeightPaper, selected && classes.active)}
        style={{ minHeight: `${size - 20}px` }}
      >
        <Paper className={clsx(classes.card, selected && classes.cardActive)}>
          <Grid container spacing={2}>
            <Grid xs={3} md={4} item>
              <img
                className={classes.img}
                src={target.profile ? target.profile : defaultProfile}
              />
            </Grid>
            <Grid item xs={9} md={8} className={classes.type}>
              {dataLine(target.full_name, classes.empty, 'body1')}
              {dataLine(target.phone_number, classes.innerType)}
              {dataLine(target.email, classes.innerType)}
              {dataLine(target.city, classes.innerType)}
              {dataLine(target.state, classes.innerType)}
              {dataLine(target.isp, classes.innerType)}
              {/*dataLine(target.Lat,classes.innerType)}
              {dataLine(target.Long,classes.innerType)*/}
              {dataLine(target.remote_id, classes.innerType)}
              {dataLine(target.user_agent, classes.innerType)}
              {dataLine(target.device, classes.innerType)}
              {dataLine(target.browser, classes.innerType)}
              {dataLine(formatted_date, classes.innerType)}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}
