import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isFirefox } from 'react-device-detect';
import theme from '../theme.js';

import clsx from 'clsx';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { changePassword } from '../Context';
import Box from '@material-ui/core/Box';
import { ReactComponent as IconSecure } from '../icn-secure.svg';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    fontFamily: 'DM Sans'
  },
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  button: {
    width: '100%',
    borderRadius: '2px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.25),
    borderWidth: theme.spacing(0.33)
  },
  lockIcon: {
    padding: theme.spacing('6px', 1, 1, 1),
    margin: theme.spacing(0, 1, 1, 1),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    '& input': {
      '-webkit-text-security': 'disc'
    },

    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #31323c inset'
    }
  }
}));

export default function SettingsButton({ handleLogout }) {
  const classes = useStyles(theme);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [password, setPassword] = useState('');
  const [dupePassword, setDupePassword] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const open = Boolean(anchorEl);
  const [successMesssage, setSuccessMessage] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setPassword('');
    setDupePassword('');
    setCurrentPassword('');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSuccessMessage(null);
    setOpenModal(false);
    setHasSubmitted(false);
  };

  const handlePasswordChangeSubmit = async () => {
    setHasSubmitted(true);
    if (currentPassword && password == dupePassword) {
      //need to implement password change api call
      let success = await changePassword(currentPassword, password);
      if (success) {
        setSuccessMessage('Password has been changed.');
      }
      setTimeout(() => handleCloseModal(), 1250);
    } else {
    }
  };

  const inputTypes = isFirefox ? 'password' : 'text';

  const modalBody = (
    <div className={clsx(classes.modal, classes.paper)}>
      {!successMesssage && (
        <React.Fragment>
          <form>
            <Box mb={1}>
              <Typography>Enter Current Password</Typography>
              <TextField
                type={inputTypes}
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="current-password"
                label="Current Password"
                id="current-password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                className={classes.input}
                helperText={
                  hasSubmitted && !currentPassword?.length
                    ? 'Enter current password'
                    : ''
                }
                error={hasSubmitted && !currentPassword?.length}
              />
            </Box>
            <Typography>Enter New Password</Typography>
            <TextField
              type={inputTypes}
              autoComplete="new-password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={classes.input}
            />
            <TextField
              type={inputTypes}
              autoComplete="new-password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={password != dupePassword && dupePassword.length}
              name="repeat-password"
              label="Repeat Password"
              id="password"
              helperText={
                password != dupePassword && dupePassword.length
                  ? 'Password Must Match'
                  : ''
              }
              value={dupePassword}
              onChange={e => setDupePassword(e.target.value)}
              className={classes.input}
            />
          </form>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handlePasswordChangeSubmit}
          >
            Submit
          </Button>
        </React.Fragment>
      )}
      {successMesssage && (
        <Typography variant="h6">{successMesssage}</Typography>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <Tooltip title="Settings">
        <IconButton
          aria-label="settings"
          onClick={handleClick}
          className={classes.lockIcon}
        >
          <IconSecure />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenModal}>Change Password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        // disableBackdropClick={true}
        aria-labelledby="passwordchange-modal-title"
        aria-describedby="passwordchange-modal-title-modal-description"
      >
        {modalBody}
      </Modal>
    </React.Fragment>
  );
}
