import { useState, useEffect } from 'react';

const LOW_SCREEN_HEIGHT = 900;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isLowScreen: height < LOW_SCREEN_HEIGHT
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useIsLowScreen() {
  const [isLowScreen, setIsLowScreen] = useState(
    getWindowDimensions().isLowScreen
  );

  useEffect(() => {
    function handleResize() {
      const { isLowScreen: nextIsLowScreen } = getWindowDimensions();
      setIsLowScreen(nextIsLowScreen);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLowScreen;
}
