import React, { useState, useEffect } from 'react';
import { loginUser, useAuthState, useAuthDispatch } from '../Context';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  error: {},
  background: {
    background: 'url(/background.png) no-repeat center center fixed',
    backgroundSize: 'cover'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  input: {
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #31323c inset'
    }
  }
}));

export default function Login(props) {
  const classes = useStyles();

  const dispatch = useAuthDispatch(); //get the dispatch method from the useDispatch custom hook
  const { loading, errorMessage } = useAuthState(); //read the values of loading and errorMessage from context

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async e => {
    e.preventDefault();
    let payload = { email, password };
    try {
      // console.log(payload);
      let response = await loginUser(dispatch, payload); //loginUser action makes the request and handles all the neccessary state changes
      // if (!response.user) return
      props.history.push('/dashboard'); //navigate to dashboard on success
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = 'Dashboard Login';
  }, []);

  return (
    <main className={clsx(classes.content, classes.background)}>
      <Container maxWidth="xl" className={classes.container}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar
              className={classes.avatar}
              alt="Eagle"
              src="/mission_avatar_large.png"
              style={{ height: '128px', width: '128px' }}
            ></Avatar>
            {errorMessage ? (
              <p className={classes.error}>{errorMessage}</p>
            ) : null}
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoFocus
                className={classes.input}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
                className={classes.input}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
                onClick={handleLogin}
              >
                Sign In
              </Button>
              {/*} <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>*/}
            </form>
          </div>
        </Container>
      </Container>
    </main>
  );
}
