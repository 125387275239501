import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import RegionActorSelector from './RegionActorSelector';
import CardContainer from './CardContainer';
import { uploadForm, exportFile, useAuthState } from '../Context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../theme.js';
import { initialState } from '../Context/reducer.js';
import { spacing } from '@material-ui/system';
import DashboardSwitch from './DashboardSwitch';

const useStyles = makeStyles(theme => ({
  drawer: {
    height: '100vh',
    overflowY: 'auto'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    // minHeight:'inier',
    height: '100vh',
    overflowY: 'auto',
    background: 'linear-gradient(#32333D, #1F2024)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  drawerPaperClose: {
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar
  },
  gridDrawer: {
    height: '100%',
    flexWrap: 'nowrap',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4)
    }
  },
  button: {
    width: '100%',
    borderRadius: '2px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.25),
    borderWidth: theme.spacing(0.33)
  },
  menuButton: {
    marginRight: 0,
    marginLeft: 0
  },
  logoWrapper: {
    marginRight: '30px',

    '& img': {
      width: '170px'
    }
  },
  menuButtonHidden: {
    display: 'none'
  },
  counter: {
    fontFamily: 'DM Mono',
    fontSize: '26px',
    color: 'black'
  },
  title: {
    flexGrow: 0,
    fontSize: 16
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    fontFamily: 'DM Sans'
  },
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  gridHidden: {
    opacity: 0
  },
  gridWithMargin: {
    marginBottom: theme.spacing(2)
  }
}));

const drawerWidth = 318;

export default function CustomDrawer({ dropdown }) {
  const classes = useStyles(theme);
  const { missionData, projectData, currentProject } = useAuthState(); //read user details from context

  const [open, setOpen] = useState(true);
  const matches = useMediaQuery('(max-width:1650px)');

  useEffect(() => {
    setOpen(!matches);
  }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const NumberBox = ({ num }) => {
    return (
      <Box
        px={1.5}
        py={0.5}
        mt={1}
        m={0.25}
        style={{ backgroundColor: 'white' }}
      >
        <Typography color="textSecondary" className={classes.counter}>
          {num}
        </Typography>
      </Box>
    );
  };

  // file upload handling
  const inputFile = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileDesc, setSelectedFileDesc] = useState('');

  const [isSelected, setIsSelected] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [region, setRegion] = useState('');
  const [actor, setActor] = useState('');
  let numMessage = '00000';
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const modalBody = (
    <div className={clsx(classes.modal, classes.paper)}>
      <Typography>Enter File Description</Typography>
      <TextField
        id="outlined-full-width"
        label="Description"
        placeholder="Description"
        fullWidth
        margin="normal"
        multiline
        InputLabelProps={{
          shrink: true
        }}
        value={selectedFileDesc}
        onChange={e => setSelectedFileDesc(e.target.value)}
        variant="outlined"
      />
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleCloseModal}
      >
        Submit
      </Button>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => {
          handleCloseModal();
          setSelectedFile(null);
          setSelectedFileDesc('');
        }}
      >
        Cancel Upload
      </Button>
    </div>
  );

  useEffect(() => {
    if (selectedFile) {
      handleOpenModal();
    } else {
      if (inputFile.current) inputFile.current.value = null;
      setIsSelected(false);
    }
  }, [selectedFile]);

  const changeHandler = event => {
    setIsUploaded(false);
    setIsError(false);
    setSelectedFile(event.target.files[0]);
    setSelectedFileDesc('');
    setIsSelected(true);
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append('ticket[title]', selectedFile.name);
    formData.append('ticket[description]', selectedFileDesc);
    formData.append('ticket[file]', selectedFile);
    uploadForm(formData)
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.error) {
          setIsError(true);
        } else {
          setIsUploaded(true);
        }
        inputFile.current.value = null;
        setIsSelected(false);
        setSelectedFile(null);
        setSelectedFileDesc('');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleExport = async () => {
    let res = await exportFile(currentProject);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}

      // open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div
          className={clsx(
            classes.logoWrapper,
            !open && classes.menuButtonHidden
          )}
        >
          <img src="/CharlieLogo.svg" />
        </div>
        {
          <IconButton
            className={clsx(
              classes.menuButton,
              !open && classes.menuButtonHidden
            )}
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        }
      </div>

      <Grid
        onClick={handleDrawerOpen}
        className={clsx(classes.gridDrawer, !open && classes.gridHidden)}
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        <div>
          <Box display="flex" justifyContent="center" mb={5}>
            <DashboardSwitch />
          </Box>

          <Grid item className={classes.gridWithMargin}>
            <RegionActorSelector open={open} dropdown={dropdown} />
          </Grid>
        </div>
        <Grid item>
          {missionData?.data?.attributes?.type == 'TargetCampaign' ? (
            <CardContainer smallPadding>
              <Typography
                color="textSecondary"
                noWrap
                className={classes.subtitle}
              >
                LEADS
              </Typography>
              <Typography color="inherit" noWrap className={classes.title}>
                Discovered
              </Typography>

              <Box justifyContent="center" display="flex">
                {missionData &&
                  missionData.leads &&
                  missionData.leads.length
                    .toString()
                    .padStart(5, '0')
                    .split('')
                    .map((v, i) => <NumberBox key={v + i} num={v} />)}
              </Box>
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={isSelected ? handleSubmission : onButtonClick}
                >
                  {isSelected ? 'Upload' : 'Import'}
                </Button>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={changeHandler}
                />
                {/*<input type="file" name="file" onChange={changeHandler} />*/}
                {isSelected && selectedFile && (
                  <div>
                    <Typography variant="body1">
                      Filename: {selectedFile.name}
                    </Typography>
                  </div>
                )}
                {isUploaded && (
                  <div>
                    <Typography variant="body1">
                      Successfully Uploaded File
                    </Typography>
                  </div>
                )}
                {isError && (
                  <div>
                    <Typography variant="body1">
                      Error Uploading File
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  disableBackdropClick={true}
                  aria-labelledby="import-modal-title"
                  aria-describedby="import-modal-description"
                >
                  {modalBody}
                </Modal>
                <Button
                  variant="outlined"
                  onClick={handleExport}
                  className={classes.button}
                >
                  Export
                </Button>
              </div>
            </CardContainer>
          ) : (
            <CardContainer smallPadding>
              <Typography
                color="textSecondary"
                noWrap
                className={classes.subtitle}
              >
                SURVEY
              </Typography>
              <Typography color="inherit" noWrap className={classes.title}>
                Respondents
              </Typography>
              <Box justifyContent="center" display="flex">
                {projectData &&
                  projectData.leads &&
                  projectData.leads.length
                    .toString()
                    .padStart(5, '0')
                    .split('')
                    .map((v, i) => <NumberBox key={v + i} num={v} />)}
              </Box>
              <Box mt={2}>
                <Button
                  variant="outlined"
                  onClick={handleExport}
                  className={classes.button}
                >
                  Export
                </Button>
              </Box>
            </CardContainer>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}
