import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';

// import { FixedSizeList } from 'react-window';
import theme from '../theme.js';
import CardContainer from './CardContainer';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 0,
    fontSize: 16
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
  },
  avatar: {
    width: theme.spacing(26),
    height: theme.spacing(26),
    padding: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: theme.spacing(0.25)
    },
    boxShadow: '0px 12px 18px rgba(0, 0, 0, 0.24)'
  },
  avatarTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1)
    }
  },
  desktop: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex'
    }
  },
  borderTitle: {
    border: '1px solid #575865',
    borderRight: 0,
    borderTop: 0,
    padding: theme.spacing(1.5)
  },

  summary: {
    // paddingTop:theme.spacing(2)
    padding: theme.spacing(1.5)
  },
  inline: {
    display: 'inline-block',
    paddingRight: theme.spacing(1.5)
  },
  missionTitle: {
    opacity: 1.0,
    color: '#595A6B',
    transition: 'opacity .25s',
    fontSize: '12px'
    // width:'95px'
  },
  white: {
    color: 'white'
  },
  blueText: {
    color: '#888DE0',
    fontWeight: 700,
    fontStyle: 'bold'
  },
  detail: {
    maxWidth: '8vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '15vw'
    }
  },
  detailBox: {
    margin: theme.spacing(0, 8),
    width: '60%',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 2)
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(0, 2)
    }
  },
  timelineTitle: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 2)
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(0, 2)
    }
  },
  closeIcon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    // height: '100%',
    // position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconHolder: {
    right: theme.spacing(2),
    position: 'absolute',

    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  timeline: {
    margin: theme.spacing(3, 3, 3, 3),
    width: `calc(80% + ${theme.spacing(0.25)}px)`,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 3, 3, 3),
      width: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      // margin:theme.spacing(3,3)
    }
  },
  timelineStepBox: {
    // paddingRight:theme.spacing(24),
    width: '90%',
    [theme.breakpoints.down('md')]: {
      // paddingRight:theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      // paddingRight:theme.spacing(3),
    }
  },
  timelineSVG: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  }
}));

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default function MissionDetail({ data, handleClose }) {
  const classes = useStyles(theme);

  let formatted_date = isValidDate(new Date(data.data.attributes.start_date))
    ? format(new Date(data.data.attributes.start_date), 'dd MMMM yyyy')
    : null;

  const timelineDetail = (title, detail, active) => {
    return (
      <CardContainer key={`${title}timeline`}>
        <Box className={classes.detail}>
          <Typography
            color="inherit"
            noWrap
            className={clsx(classes.missionTitle, active && classes.blueText)}
          >
            {title}
          </Typography>
          <Typography className={clsx(classes.missionTitle, classes.white)}>
            {detail}
          </Typography>
        </Box>
      </CardContainer>
    );
  };

  return (
    // <Grid container spacing={3}>
    <Box
      spacing={3}
      position="absolute"
      style={{ top: 0, width: 'calc(100% - 4rem)' }}
    >
      <IconButton onClick={() => handleClose()} className={classes.iconHolder}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <Box display="flex">
        <Avatar
          alt="Mission Avatar"
          src="/mission_avatar_large.png"
          className={clsx(classes.avatar, classes.desktop)}
        />
        <Box className={classes.detailBox}>
          <Box display="flex">
            <Avatar
              alt="Mission Avatar"
              src="/mission_avatar_large.png"
              className={clsx(classes.avatar, classes.mobile)}
            />
            <Typography variant={'h5'} className={classes.avatarTitle}>
              {data.data.attributes.name}
            </Typography>
          </Box>
          <Box pt={4}>
            <Grid container>
              <Grid item xs={4} className={classes.borderTitle}>
                <Typography className={classes.subtitle} color="textSecondary">
                  Strategy
                </Typography>
                <Typography className={classes.title}>
                  {data.data.attributes.strategy}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.borderTitle}>
                <Typography className={classes.subtitle} color="textSecondary">
                  Start Date
                </Typography>
                <Typography className={classes.title}>
                  {formatted_date}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.borderTitle}>
                <Typography className={classes.subtitle} color="textSecondary">
                  Status
                </Typography>
                <Typography className={classes.title}>
                  {data.data.attributes.status}{' '}
                  <span
                    className={classes.blueText}
                  >{`${data.data.attributes.status_pct}%`}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.summary}>
                <Typography variant={'body2'}>
                  {data.data.attributes.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/*<Box display="flex">*/}
      <Box className={classes.timelineTitle}>
        <Typography className={classes.subtitle} color="textSecondary">
          Operation
        </Typography>
        <Typography className={clsx(classes.title, classes.inline)}>
          Timeline
        </Typography>
        <Typography className={clsx(classes.title, classes.inline)}>
          <span className={classes.blueText}>
            {`${data.data.attributes.status_pct}%`} COMPLETE
          </span>
        </Typography>
      </Box>
      <Box display={'flex'} className={classes.timeline}>
        <svg
          width="100%"
          height="100%"
          style={{ minHeight: '24px' }}
          viewBox="0 0 773 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9" cy="12" r="12" fill="#2F3039" />
          <circle cx="197" cy="12" r="12" fill="#2F3039" />
          <circle cx="385" cy="12" r="12" fill="#2F3039" />
          <circle cx="573" cy="12" r="12" fill="#2F3039" />
          <circle cx="761" cy="12" r="12" fill="#2F3039" />
          <path
            d="M6 12.0001L760 12.0001"
            stroke="#2F3039"
            strokeWidth="12"
            strokeLinecap="round"
          />
          <path
            d="M6 12.0001L760 12.0001"
            stroke="url(#paint0_linear)"
            strokeWidth="6"
            strokeLinecap="round"
          />
          <circle cx="9" cy="12" r="8" fill="url(#paint1_linear)" />
          <circle cx="197" cy="12" r="8" fill="url(#paint1_linear)" />
          <circle cx="385" cy="12" r="8" fill="url(#paint1_linear)" />
          <circle cx="573" cy="12" r="8" fill="url(#paint1_linear)" />
          <circle cx="761" cy="12" r="8" fill="url(#paint1_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="0"
              y1="12"
              x2="773"
              y2="12"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset={data.data.attributes.status_pct / 100.0 - 0.05}
                stopColor="#888DE0"
              />
              <stop
                offset={data.data.attributes.status_pct / 100.0 + 0.05}
                stopColor="#888DE0"
                stopOpacity="0"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="0"
              y1="12"
              x2="773"
              y2="12"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset={data.data.attributes.status_pct / 100.0 - 0.05}
                stopColor="#888DE0"
              />
              <stop
                offset={data.data.attributes.status_pct / 100.0 + 0.05}
                stopColor="#2F3039"
              />
            </linearGradient>
          </defs>
        </svg>
        <Box mr={-2} ml={2} mb={0} mt={0} display="blocks">
          <Typography
            color="inherit"
            noWrap
            style={{ fontSize: '22px' }}
            className={classes.blueText}
          >
            {`${data.data.attributes.status_pct}%`}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.timelineStepBox}
      >
        {data.included &&
          data.included
            .filter((v, i) => v.type == 'project_steps')
            .map((v, i) => {
              let title = `0${v.attributes.order}. ${v.attributes.title}`;
              return timelineDetail(
                title,
                v.attributes.description,
                v.attributes.completed
              );
            })}
      </Box>
    </Box>
    // </Grid>
  );
}
