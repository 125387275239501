import { Link, Redirect, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ColumnHolder from '../Components/nci/ColumnHolder';
import { ReactComponent as Arrow } from '../Components/Psyop/arrow.svg';
import _ from 'lodash';
import ReactSvgMap from '../Components/nci/ReactSvgMap';
import theme from '../theme';
import { ColorLine, useStyles } from './NCIDashboard';
import TopToolbar from '../Components/nci/TopToolbar';
import {
  YAxis,
  BarChart,
  Bar,
  LabelList,
  Cell,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { fetchNciCountry } from '../Api';
import LoadingIndicator from '../Components/LoadingIndicator';

export default function CountryDetails({ region, country }) {
  const classes = useStyles(theme);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [totalScore, setTotalScore] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const fetchDetails = async () => {
    if (country?.actor_id) {
      setIsLoading(true);
      const _c = await fetchNciCountry(country.actor_id);
      const sortedCategory = _.sortBy(
        _c.data?.attributes?.categories,
        'category_name'
      );
      const preparedCategories = sortedCategory.map(category => ({
        ...category,
        score: _.round(_.toNumber(category.score), 1) || 0
      }));
      if (preparedCategories) {
        setCategories(preparedCategories);
        setTotalScore(_.toNumber(_c.data?.attributes?.total_score) || null);
        if (preparedCategories[0]?.category_id) {
          setCategoryId(preparedCategories[0]?.category_id);
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [country?.actor_id]);

  const renderCustomizedLabel = React.useCallback(
    props => {
      const { x, y, width, height, value, name } = props;
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + height - 16}
            fill="#fff"
            // fill={selectedAnswer == responseData[index].id ? '#888DE0' : '#fff'}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: 18,
              fontWeight: 800
            }}
          >
            {value}
          </text>
          <text
            x={x + width / 2}
            y={y + height + 18}
            fill="#898AA1"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: 12,
              fontWeight: 700
            }}
          >
            {name}
          </text>
        </g>
      );
    },
    [country?.actor_id]
  );

  if (!region || !country) {
    return <Redirect to="/nci" />;
  }

  const category = _.find(categories, { category_id: categoryId });
  const chartData = _.map(_.get(category, 'questions.answers'), answer => ({
    id: answer?.answer_id,
    name: answer?.answer_content,
    value: answer?.count || 0
  }));

  return (
    <>
      <Box
        className={clsx(
          isLoading ? classes.fadeIn : classes.fadeOut,
          classes.loading
        )}
      >
        <LoadingIndicator />
      </Box>
      <Container maxWidth="xl" className={classes.mission_container}>
        <TopToolbar
          title={
            <>
              <Link to="/nci">{_.upperCase(region.name)}</Link>
              {` > ${_.capitalize(country.actor_name)}`}
            </>
          }
        />
      </Container>
      {!isLoading && categories && (
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3} className={clsx(classes.parentGrid)}>
            <ColumnHolder
              subtitle="NATIONAL CONFIDENCE INDEX"
              gridProps={{
                lg: 4,
                sm: 12
              }}
              noHorizontalPadding
            >
              <Box marginTop="15px" display="flex" flex={1}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" className={classes.reportBox}>
                    <Typography variant="h2" className={classes.reportNumber}>
                      {totalScore || '-'}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Arrow
                        className={clsx(
                          classes.indexArrow,
                          classes.arrowUp,
                          true ? classes.arrowUpActive : ''
                        )}
                      />
                      <Arrow
                        className={clsx(
                          classes.indexArrow,
                          classes.arrowDown,
                          false ? classes.arrowDownActive : ''
                        )}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      flex={1}
                      alignItems="flex-end"
                    >
                      <Typography className={classes.reportName}>
                        {_.get(country, 'actor_name')}’s Total Score
                      </Typography>
                      <Typography className={classes.reportInfo}>
                        +0% Overall Sentiment
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={4} pb={4} borderBottom="1px solid #454654">
                    <div className="nci-report__list">
                      {_.map(categories, _category => {
                        const score = _.get(_category, 'score', 0);
                        const colorLine = ColorLine.find(
                          ({ value }) => value < score
                        );
                        return (
                          <div
                            key={_category.category_id}
                            className={`nci-report__list-item ${
                              _category.category_id === categoryId
                                ? 'nci-report__list-item--selected'
                                : ''
                            }`}
                            onClick={() => setCategoryId(_category.category_id)}
                          >
                            <span className="nci-report__list-name">
                              {_category.category_name}
                            </span>
                            <div className="nci-report__list-bar-wrapper">
                              <span className="nci-report__list-bar">
                                <span
                                  className="nci-report__list-bar-result"
                                  style={{
                                    width: `${score}%`,
                                    backgroundColor: colorLine.color
                                  }}
                                />
                              </span>
                              <span className="nci-report__list-result">
                                {score}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                  {category && (
                    <Box padding={1} mt={2} pb={1}>
                      <div className="nci-report__survey">
                        <div className="nci-report__survey-header">
                          <div className="nci-report__survey-title">
                            Survey Question
                          </div>
                          <div className="nci-report__survey-select">
                            {category.category_name}
                          </div>
                        </div>
                        <div className="nci-report__survey-question">
                          {_.get(category, 'questions.question_content')}
                        </div>
                        {/*<div className="nci-report__survey-actions">*/}
                        {/*  <div className="nci-report__survey-prev">*/}
                        {/*    <span>{'<'}</span> Previous*/}
                        {/*  </div>*/}
                        {/*  <div className="nci-report__survey-next">*/}
                        {/*    Next <span>{'>'}</span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div
                          className="nci-report__survey-chart"
                          style={{
                            width: '100%',
                            height: 200
                          }}
                        >
                          <Box
                            display="flex"
                            height="inherit"
                            mt={3}
                            style={{
                              // flexGrow: 0
                              maxHeight: '720px',
                              minHeight: '20%'
                            }}
                          >
                            <ResponsiveContainer
                              width="99%"
                              // height="66%"
                              minHeight="100"
                              debounce={0}
                            >
                              <BarChart
                                width={'inherit'}
                                height={300}
                                data={chartData}
                                interval={0}
                                margin={{
                                  top: 20,
                                  right: 0,
                                  left: -20,
                                  bottom: 30
                                }}
                              >
                                <CartesianGrid
                                  stroke="#454654"
                                  strokeDasharray=""
                                  vertical={false}
                                />
                                <YAxis
                                  domain={[0, _.max(_.map(chartData, 'value'))]}
                                  dataKey="value"
                                  axisLine={false}
                                  tickLine={false}
                                  padding={{ top: 0, bottom: 0, left: 0 }}
                                />
                                <Bar
                                  // data={responseData}
                                  dataKey="value"
                                  nameKey="answer"
                                  stroke="white"
                                  maxBarSize={100}
                                  fill="none"
                                  interval={0}
                                  // label={renderLabel}
                                  // label={entry => entry}

                                  // label={{
                                  //   stroke: 'none',
                                  //   fill: 'white',
                                  //   fontSize: 18,
                                  //   fontWeight: 800
                                  // }}
                                >
                                  <LabelList
                                    dataKey="value"
                                    nameKey="name"
                                    data={chartData}
                                    content={props =>
                                      renderCustomizedLabel(props)
                                    }
                                  />
                                  {_.map((entry, index) => (
                                    <Cell
                                      // onClick={() => {
                                      //   setAnswer(entry.id);
                                      // }}
                                      key={`${entry}cell`}
                                      cursor="pointer"
                                      fill={'transparent'}
                                      stroke={
                                        // entry.id === selectedAnswer
                                        //   ? '#888DE0'
                                        //   :
                                        'white'
                                      }
                                    />
                                  ))}
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </Box>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Box>
            </ColumnHolder>
            <ColumnHolder
              desc={
                <>
                  <Link to="/nci">{region?.name}</Link>
                  {` > ${country?.actor_name}`}
                </>
              }
              gridProps={{
                lg: 8,
                sm: 12
              }}
            >
              <ReactSvgMap
                key={country?.actor_name}
                countries={[{ name: country?.actor_name, value: 0 }]}
              />
            </ColumnHolder>
          </Grid>
        </Container>
      )}
    </>
  );
}
