/* eslint-disable no-use-before-define */

import React, { useEffect } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from '../../theme.js';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';

import SettingsButton from '../SettingsButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { logout, useAuthDispatch } from '../../Context';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginLeft: '12px',
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    },
    '& a:hover': {
      opacity: 0.8
    }
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: '0px'
    // transform:'none'
  },
  mission_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  missionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar,

    minHeight: 'auto !important'
  },
  grid: {
    // paddingLeft:theme.spacing(8),
    // paddingRight:theme.spacing(8),
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: theme.spacing(0.25)
    },
    boxShadow: '0px 12px 18px rgba(0, 0, 0, 0.24)'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#575865', 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    '& .MuiInputLabel-shrink': {
      display: 'none'
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  },
  searchIcon: {
    right: 0,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    // color: 'inherit',
    '&:hover': {
      backgroundColor: 'none'
    },
    color: theme.palette.common.white
  },
  inputInput: {
    color: theme.palette.common.white,
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('width'),
    width: '100%',
    '& .MuiInputLabel-shrink': {
      opacity: 0
      // transform: 'translate(0, 1.5px)!important',
    },
    '& .MuiInputLabel-formControl': {
      // transform : 'translate(0, 30px)'
    }

    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  rowActive: {
    opacity: 1.0,
    outline: 'solid 1px',
    color: 'white',
    transition: 'opacity .25s'
  },
  missionTitle: {
    opacity: 1.0,
    color: '#595A6B',
    transition: 'opacity .25s',
    fontSize: '12px'
    // width:'95px'
  },
  percentage: {
    color: '#888DE0',
    fontStyle: 'bold',
    fontWeight: 700
  },
  blueText: {
    color: '#888DE0'
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  buttonWithLowScreen: {
    padding: '0 0 0 12px',

    '& .MuiAvatar-root': {
      height: '67px !important',
      width: '67px !important'
    }
  },
  boxDrop: {
    flex: 1,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      // width: '80%',
      padding: theme.spacing(0, 0.5)
    }
  },
  formControl: {
    '& label': {
      textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px)!important'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 30px)'
    }
  },
  formControlWithLowScreen: {
    padding: theme.spacing(0, 2),

    '& .MuiInputBase-root': {
      fontSize: '18px'
    }
  },
  hideLowScreen: {
    display: 'none'
  },
  gridLowHeight: {
    '& label + .MuiInput-formControl': {
      marginTop: 0
    }
  },
  headerContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  headerContainerWithLowScreen: {
    position: 'relative',

    '&:after': {
      [theme.breakpoints.up('md')]: {
        content: '" "',
        bottom: '14px',
        left: '75px',
        position: 'absolute',
        right: 0,
        height: '1px',
        backgroundColor: '#575865',
        zIndex: 1
      }
    }
  },
  mainHeader: {
    flexBasis: '66%',
    maxWidth: '66%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: 'none'
    },

    '& #nav-content': {
      flex: 1,
      marginBottom: '-1px'
    }
  },
  mainHeaderWithLowScreen: {
    flex: 1,
    maxWidth: 'none'
  },
  links: {
    flexGrow: 1
  },
  linksWithLowScreen: {
    flexGrow: 0,

    '& .MuiButtonBase-root': {
      padding: '14px',

      '& svg': {
        height: '18px'
      }
    }
  },
  boxDropWithLowScreen: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacing(2),
    paddingLeft: 0,

    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      display: 'block'
    },

    '& .MuiTab-root': {
      padding: theme.spacing(0.5, 3)
    }
  }
}));

export default function TopToolbar({ handleDetailOpen, title }) {
  const history = useHistory();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const classes = useStyles(theme);

  const handleLogout = () => {
    logout(dispatch);
    history.push('/login');
  };

  return (
    <Box
      container
      className={clsx(
        classes.headerContainer,
        classes.headerContainerWithLowScreen,
        classes.gridLowHeight
      )}
    >
      <Box
        className={clsx(classes.mainHeader, classes.mainHeaderWithLowScreen)}
      >
        <div className={classes.missionBar}>
          <Tooltip title="NCI">
            <IconButton
              className={clsx(classes.button, classes.buttonWithLowScreen)}
              onClick={handleDetailOpen}
              disabled
            >
              <Avatar
                alt="NCI Avatar"
                src="/nci_avatar.png"
                className={classes.avatar}
              />
            </IconButton>
          </Tooltip>
          <Typography color="inherit" noWrap className={clsx(classes.title)}>
            {title}
          </Typography>
        </div>
      </Box>
      <Box className={clsx(classes.links, classes.linksWithLowScreen)}>
        <Box display="flex" pt={1} justifyContent="flex-end">
          <Box display="flex-inline">
            <SettingsButton handleLogout={handleLogout} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
